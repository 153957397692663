import * as React from "react";
import {useEffect, useState} from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputBase,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import {createStyles, makeStyles, Theme, withStyles,} from "@material-ui/core/styles";
import Dropzone from "react-dropzone";
import { environment } from "src/environments/environment";
import { UploadFile } from "src/components/Icons";
import BrowseButton from "src/components/sub-components/BrowseButton";
import { primaryBlue, primaryGray, searchBarBorderColor } from "src/components/colors";
import { DragDrop } from "src/components/sub-components/DragAndDrop";
import axios from "axios";
import useStyles from "../../assets/styles";
import { TextBar } from "src/components/sub-components/TextBar";


export function Step03(props) {
  const classes = useLocalStyles();
  const styles = useStyles();  

  const selectedProductType = props.selectedProductType;
  let flowChartUrls = [];


  useEffect(() => {
    axios.get(`${environment.host}/core/product/configDetails`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (response.data.content !== undefined) {
          const selectedProduct = response.data.content.productTypes[0].find(
            (product) => product.productName === selectedProductType
          );
          if (selectedProduct && selectedProduct.versions) {
            let uniqueFlowCharts=new Map();
            flowChartUrls = selectedProduct.versions
              .filter((version) => version.flowChartURL) 
              .forEach((version) => {
                let key=version.flowChartFileName;
                if (!uniqueFlowCharts.has(key)){
                  uniqueFlowCharts.set(key,{fileName:version.flowChartFileName,URL:version.flowChartURL});
               }})
               let uniqueFlowChartsArray=Array.from(uniqueFlowCharts.values());
          props.setExistingFlowChartList(uniqueFlowChartsArray.length > 0 ? uniqueFlowChartsArray : []);
              }
  }
})
      .catch((err) => {
        props.setExistingFlowChartList([]);
      });
  }, [selectedProductType]);
  const handleAddFlowChart=(checked)=>{
    props.setAddFlowChart(checked);
    if(!checked){
      props.resetAddFlowChart();
    }

  }
  return (
    <div className={`testing`}>
          <Grid container className={`${styles.root} ${styles.formContent}`}>
          <Grid
        item
        xs={12}
        md={12}
        style={{
          textAlign: "left",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
          backgroundColor:`${ props.addFlowChart && `rgba(42, 124, 237, 0.1)`}`,
        }}
      >
        
        <FormControlLabel
          label={""}
          control={
            <Checkbox
              className={styles.radio}
              checked={props.addFlowChart}
              style={{padding: "0px",marginRight:"20px"}}
              onChange={(e) => {
                handleAddFlowChart(e.target.checked);
              }}
            />
          }
          labelPlacement="start"
        />
        <Typography className={`${classes.label} ${props.addFlowChart && classes.labelSelected}`}>Add Technology Flow Chart</Typography>
      </Grid>
<Grid container className={`${!props.addFlowChart && styles.blur}`}>


<Grid item xs={6} md={6}
      onClick={() => props.handleSwitchTab('select')}
      className={`${styles.formInput} ${classes.tabHeaderStyle} ${props.addFlowChart && classes.labelSelected}`}
      style={{
        borderBottom: props.addFlowChart && props.tab === 'select' ? '3px solid #2A7CED' : '1px solid #ccc',padding:"5px"
      }}
>
  <div style={{ marginLeft: '8px' }}>
    Select Existing Flow Chart
  </div>
</Grid>

<Grid item xs={6} md={6}
      onClick={() => props.handleSwitchTab('create')}
      className={`${styles.formInput} ${classes.tabHeaderStyle} ${props.addFlowChart && classes.labelSelected}`}
      style={{
        borderBottom:props.addFlowChart && props.tab === 'create' ? '3px solid #2A7CED' : '1px solid #ccc',padding:"5px"
      }}
>
  <div style={{ marginLeft: '8px' }}>
    Create New Flow Chart
  </div>
</Grid>
 
  
      
        {props.tab==='select'&&(
      <Grid
        item
        xs={12}
        md={12}
        style={{textAlign: "left", marginBottom: "20px"}}
      >
        <Typography className={styles.label}>
          Existing Flowchart File
        </Typography>
        <FormControl className={classes.input}>
          <Select
            id="demo-customized-select-native"
            placeholder="Select existing flowchart file"
            value={props.defaultFlowChart}
            className={classes.input}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
              classes: {paper: classes.menuPaper},
            }}
            onChange={(e) => props.readExistingFlowChart(e.target.value)}
            input={<BootstrapInput/>}
          >
            {props.existingFlowChartList.map((chart, i) => (
              <MenuItem value={chart} key={i}>
                <ListItemText primary={chart.fileName?chart.fileName:"Unknown file"} style={{color: primaryGray}}/>
              </MenuItem>
            ))}
          </Select>
          {props.flowChartError === true && (
            <FormHelperText className={styles.helperText}>
              Flow Chart Not Selected *
            </FormHelperText>
          )}
        </FormControl>
      </Grid>)
      }
      {props.tab!=='select' && (
      <>
      <Grid item xs={12} md={12} style={{marginBottom:"20px"}}>
          <Typography
            className={classes.label}
            style={{ textAlign: "left" }}
          >
            Flow Chart File Name
            <span style={{ color: "red" }}>*</span>
          </Typography>
          <FormControl className={classes.input}>
            <TextBar
              error={{ error: false }}
              defaultValue={props.flowChartFileName}
              className={classes.input}
              onChange={(e) => props.readFileName(e.target.value)}
              style={classes.input}
            />
            {props.flowChartFileNameError.isError === true && (
              <FormHelperText className={styles.helperText}>
                {props.flowChartFileNameError.message}
              </FormHelperText>
            )}
          </FormControl>
      </Grid>
      <Grid item xs={12} md={12} style={{textAlign: "left"}}>
        <Typography className={classes.label}>
          Upload the Flowchart file here
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Dropzone
          onDrop={(acceptedFiles) => props.readFlowChart(acceptedFiles)}
          accept={{
            "image/jpeg": [".jpg"], 
            "application/pdf": [".pdf"], 
          }}
        >
          {({getRootProps, getInputProps}) => (
            <section>
              <div
                {...getRootProps()}
                className={styles.dragDrop}
              >
                <input {...getInputProps()} />
                <DragDrop/>
                <Typography style={{textAlign:"center"}}>
                  <span className={styles.dragText}
                  >
                    {props.chartFilename === ""
                      ? "Drag & Drop file here to upload or"
                      : props.chartFilename}
                  </span>
                  <BrowseButton
                    isFileSelected={props.chartFilename != ""}
                  />
                </Typography>
              </div>
            </section>
          )}
        </Dropzone>
        {props.flowChartError === true && (
          <FormHelperText className={styles.helperText}>
            Flowchart File not uploaded *
          </FormHelperText>
        )}
      </Grid>
      </>)
      }
      </Grid>
    </Grid>
    </div>
  );
}

const useLocalStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px",
  },
  input: {
    width: "100%",
    maxWidth: "100%",
    height: "40px",
    backgroundColor: "#F4F5F7",
  },
  label: {
    fontSize: "15px",
    color: primaryGray,
    paddingBottom: "5px",
  },
  menuPaper: {
    maxHeight: "200px",
  },
  labelSelected:{
    color:primaryBlue,
    opacity:1
    },
  tabHeaderStyle: {
      cursor: 'pointer',
      padding: '0',
      textAlign: 'center',
      color: primaryGray,
      backgroundColor: 'white',
      borderRadius: '4px 0 0 4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '16px', // Adjust font size as needed
      height: '100%',   // Ensures the same height for both items
    }
}));

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: "relative",
      backgroundColor: "white",
      border: "1px solid #ced4da",
      fontSize: 16,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      // Use the system font instead of the default Poppins font.
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Poppins",

        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:focus": {
        borderRadius: 4,
        borderColor: searchBarBorderColor,
        backgroundColor: "white",
      },
    }

  })
)(InputBase);
