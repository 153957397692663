import {FormControl, FormControlLabel, Grid, RadioGroup, Typography} from '@material-ui/core';
import * as React from 'react';


export function ViewDeviceComponent(props) {

  const onClose = () => {
    props.isEyeIconClick(prevState => ({
      ...prevState,
      device: false
    }))
  }
  return (
    <div>
      <aside>
        <div className="modal_HighZindex" id="addKitModel">
          <div className="custom-modal-content">
            <Grid container className="modal-header" style={{textAlign: 'left'}}>
              <Grid item xs={7} md={7}>
                <Typography className={props.style.popupHeading}>
                  View Device
                </Typography>
              </Grid>
              <Grid item xs={5} md={5} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <button className="modal-close" style={{color: "#666D73"}} onClick={() => onClose()}>&times;</button>
              </Grid>
            </Grid>
            <div className={`testing`} style={{margin: '0', padding: "20px"}}>
              <Grid container spacing={1}>
                    <Grid item xs={6} md={6}>
                      <Typography className={props.classes.text}>
                        Device Id(IMEI)
                      </Typography>
                        <input
                          className={props.style.form}
                          type="text"
                          value={props.selectDevice.id}
                          disabled={true}
                        />
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Typography className={props.classes.text}>
                        Device Name
                      </Typography>
                        <input
                          className={props.style.form}
                          type="text"
                          value={props.selectDevice.name}
                          disabled={true}
                        />
                    </Grid>
              </Grid>

              <Grid container spacing={1}>
                    <Grid item xs={6} md={6}>
                      <Typography className={props.classes.text}>
                        Interval
                      </Typography>
                      <FormControl className={props.classes.input}>
                        <input
                          className={props.style.form}
                          type="text"
                          value={props.selectDevice.interval}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Typography className={props.classes.text}>
                        Persistence
                      </Typography>
                      <FormControl className={props.classes.input}>
                        <input
                          className={props.style.form}
                          type="text"
                          value={props.persistance === "true" ? "True" : "False"}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
              </Grid>


              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Typography className={props.classes.text}>
                        Sensors
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={1}></Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl className={props.classes.input}>
                        <Grid container>
                          {props.selectSensors.length == 0 ?
                            <Grid container>
                              <input
                                className={props.style.form}
                                type="text"
                                placeholder={props.selectSensors.length != 0 ? "" : "No Sensor Added"}
                                // value={sensorValue}
                                disabled={true}
                              />
                            </Grid>
                            :
                            <Grid container style={{
                              padding: '10px 0px',
                              overflowY: 'auto',
                            }}>
                              {props.selectSensors.map((sensor, index) => {
                                return (
                                  <Grid container key={index} className={props.style.cloudItem}>
                                    <Grid item className={props.style.cloudItem_number}>{index + 1}</Grid>
                                    <Grid item className={props.style.cloudItem_line}></Grid>
                                    <Grid item className={props.style.cloudItem_text}>{sensor.name}</Grid>
                                  </Grid>
                                )
                              })
                              }
                            </Grid>
                          }
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Typography className={props.classes.text}>
                        Actuators
                      </Typography>
                    </Grid>
                    <Grid item xs={4} md={1}></Grid>
                    <Grid item xs={12} md={12}>
                      <FormControl className={props.classes.input}>
                        <Grid container>
                          {props.selectActuator.length == 0 ?
                            <Grid container>
                              <input
                                className={props.style.form}
                                type="text"
                                placeholder={props.selectActuator.length != 0 ? "" : "No Actuator Added"}
                                // value={sensorValue}
                                disabled={true}
                              />
                            </Grid>
                            :
                            <Grid container style={{
                              padding: '10px 0px',
                              overflowY: 'auto',
                            }}>
                              {props.selectActuator.map((actuator, index) => {
                                return (
                                  <Grid container key={index}  className={props.style.cloudItem}>
                                    <Grid item className={props.style.cloudItem_number}>{index + 1}</Grid>
                                    <Grid item className={props.style.cloudItem_line}></Grid>
                                    <Grid item className={props.style.cloudItem_text}>{actuator.name}</Grid>
                                  </Grid>
                                )
                              })
                              }
                            </Grid>
                          }
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                    <Grid item xs={6} md={6}>
                      <Typography className={props.classes.text}>
                        Protocol
                      </Typography>
                      <FormControl className={props.classes.input}>
                        <input
                          className={props.style.form}
                          type="text"
                          value={props.selectDevice.protocol}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Typography className={props.classes.text}>
                        Connectivity
                      </Typography>
                      <FormControl className={props.classes.input}>
                        <input
                          className={props.style.form}
                          type="text"
                          value={props.selectDevice.connectivity}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
              </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={6}>
                      <Typography className={props.classes.text}>
                        Batch
                      </Typography>
                      <FormControl className={props.classes.input}>
                        <input
                          className={props.style.form}
                          type="text"
                          value={props.selectDevice.batchNumber? props.selectDevice.batchNumber: "No Batch Added"}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Typography className={props.classes.text}>
                        Sim Number
                      </Typography>
                      <FormControl className={props.classes.input}>
                        <input
                          className={props.style.form}
                          type="text"
                          value={props.selectDevice.simNumber? props.selectDevice.simNumber: "No SIM Number"}
                          disabled={true}
                        />
                      </FormControl>
                    </Grid>
                 </Grid>


            </div>
          </div>
        </div>
      </aside>
    </div>
  )
}
