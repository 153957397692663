import * as React from "react";
import { Button, CircularProgress, createTheme, Grid, Snackbar, ThemeProvider, Typography } from "@material-ui/core";
import useStyles from "../assets/styles";
import { useState, useEffect } from "react";
import {useHistory} from 'react-router-dom';
import { lightGray, primaryBlue, primaryGray, white } from "src/components/colors";
import { DropDown } from "./DropDown";
import { Step1 } from "./step1";
import { Step2 } from "./step2";
import { Step3 } from "./step3";
import axios from "axios";
import { environment } from "src/environments/environment";
import { Alert } from "@material-ui/lab";
import AlertPopup from "../AlertPopup";


const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const STEPS = [
  {
    text: "Configure Devices",
    activate: true,
  },
  {
    text: "Network & Communication",
    activate: false,
  },
  {
    text: "Topic Format, Message, Format & Interval",
    activate: false,
  },
];

export function RemoteManagerComponent() {
  const classes = useStyles();
  const history = useHistory();
  const [steps, setSteps] = useState(STEPS);
  const [step, setStep] = useState(0);
  const [selectedVersion,setSelectedVersion]=useState('');
  const [selectedDeviceIds,setSelectedDeviceIds]=useState([]);
  const [selectedProductType,setSelectedProductType]=useState({
    id:"",
    versions:[],
    productName:'',
    versionNum:'',
    deviceParameterConfiguration:{remoteConfigurations:[]}
  });
  const [productTypes,setProductTypes]=useState([]);
  const [versions,setVersions]=useState([]);
  const [deviceIds,setDeviceIds]=useState([]);
  const [detailsArray,setDetailsArray]=useState([]);
  const [message, setMessage] = useState('');
  const [toaster, setToaster] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors,setErrors]=useState({
    productTypeError:{error:false,errorMsg:""},
    versionError:{error:false,errorMsg:""},
    deviceIdError:{error:false,errorMsg:""}
  });
  const [changeInDeviceIds,setChangeInDeviceIds]=useState(false);
  const [openPopup,setOpenPopup]=useState(false);


  useEffect(() => {

    updateStepActivation(step);
  }, [step]);

  function updateStepActivation(currentStep) {
    const updatedSteps = steps.map((step, index) => ({
      ...step,
      activate: index === currentStep,
    }));
    setSteps(updatedSteps);
  }

  const handleCloseToaster = () => {
    setToaster(false);
  };


  async function fetchDeviceConfig(deviceId){
    setLoading(true);
   await  axios.get(`${environment.host}/core/device/${deviceId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        if (response.data.content !== undefined) {
              let config=response.data.content.deviceParameterConfiguration.remoteConfigurations;
              setDetailsArray(config);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('Failed to get  device Configurations', err);
        setLoading(false);
        let errMsg=err?err.response.data.message:"Failed to get  device Configurations'";
        setMessage(errMsg);
        setMsgSuccess(false);
        setToaster(true);
        setTimeout(() => {
          setToaster(false);
        }, 2000);
      });
   return [];
  }
  function setProductTypeConfigparams(productTypeRemoteConfig){
   setDetailsArray(productTypeRemoteConfig.map(({...item}) => ({
    ...item,
    defaultValue:''
  })),)
  }
  const getRemoteConfiguration=async()=>{
    
      try{
      if (selectedDeviceIds.length==1){
        
       await fetchDeviceConfig(selectedDeviceIds[0]);
       setChangeInDeviceIds(false);

      }
      else{
        let remoteConfigForProductTypeVersion;
        selectedProductType.versions.forEach(version => {
          if (version.versionNum==selectedVersion){
            remoteConfigForProductTypeVersion=version.remoteConfigurations;
          }
        });
        if (remoteConfigForProductTypeVersion){
        setProductTypeConfigparams(remoteConfigForProductTypeVersion);
        }
        else{
          
          setMessage("cannot find remote configuration");
          setMsgSuccess(false);
          setToaster(true);
          setTimeout(() => {
          setToaster(false);
          }, 4000);

        }
      }}
      catch(err){}      
  }

  function renderStepsComponent() {
 
      if (step === 1) {
          return <Step2 detailsArray={detailsArray} setDetailsArray={setDetailsArray} selectedDeviceIds={selectedDeviceIds} setErrors={setErrors}/>
       } else if(step === 2) {
          return <Step3 detailsArray={detailsArray} setDetailsArray={setDetailsArray} />;
        } else{
          return <Step1 selectedVersion={selectedVersion} 
                        setSelectedVersion={setSelectedVersion} 
                        selectedDeviceIds={selectedDeviceIds} 
                        setSelectedDeviceIds={setSelectedDeviceIds}
                        setSelectedProductType={setSelectedProductType} 
                        selectedProductType={selectedProductType}
                        setproductTypeRemoteConfigs={setProductTypeConfigparams}
                        setLoading={setLoading}
                        errors={errors}
                        setErrors={setErrors}
                        setProductTypes={setProductTypes}
                        setVersions={setVersions}
                        setDeviceIds={setDeviceIds}
                        productTypes={productTypes}
                        deviceIds={deviceIds}
                        versions={versions}
                        setChangeInDeviceIds={setChangeInDeviceIds}
                        setMsgSuccess={setMsgSuccess}
                        setToaster={setToaster}
                        setMessage={setMessage}
            
                        />;}
      
    }
    function isCharValid(value) {
      return /^.$/.test(value); // any single character
     // return /^[a-zA-Z]$/.test(value);  //any single alphabet
  }
  function isNumeric(value) {
    return !isNaN(value) && !isNaN(parseFloat(value));
  }
  function isStrictInteger(value) {
    if(isNaN(value)){
      return false; // Return false for invalid numbers
    }
    const num = parseFloat(value);
    return Number.isInteger(num) && value.indexOf('.') === -1;
  }

    const  checkDefaultValueFormat=(format,defaultValue,parameterName)=>{
      let result={error:false,errorMsg:""}

      if(!(defaultValue.length>0)){
        result={error:true,errorMsg:`${parameterName} is required`}
          return result;
      }
      format = format.toLowerCase();
      switch (format) {
          case "string":
            if (isNumeric(defaultValue)) {
              result={error:true,errorMsg:"Input cannot be 'integer' only."}
             }
             break
          case "char":
             if (!isCharValid(defaultValue)){
              result={error:true,errorMsg:"Cannot add more than 1 character"}
             }
              break;
          case "double":
          case "float":
              if (!isNumeric(defaultValue)) {
                result={error:true,errorMsg:"Input must be a numeric value"}
              }
              break;
          case "integer":
              if (!isStrictInteger(defaultValue)) {
                result={error:true,errorMsg:"Input must be an integer"}
              }
              break;
          default:
              break;
      }

      return result;
  }
  const updateDetailsArrayErrors = (prevArray,parameterCategory) => {
    return prevArray.map((detail) => {
      if (detail.checkUpdate &&detail.parameterCategory==parameterCategory) {
        const validationResult = checkDefaultValueFormat(detail.format, detail.defaultValue,detail.parameter);
        return validationResult.error
          ? { ...detail, errorMsg: validationResult.errorMsg }
          : { ...detail, errorMsg: "" };
      }
      return detail;
    });
  };
  function increaseStep() {
    if (step==0){
    let deviceIdEmptyError=!(selectedDeviceIds.length>0);
    let productTypeEmptyError=!(selectedProductType.productName.length>0);
    let versionEmptyError=!(selectedVersion.length>0);

    if (productTypeEmptyError)setErrors(prev => ({
      ...prev,
      productTypeError: { error: true, errorMsg: "Selecting a product type is required" }
    }))
    else if (versionEmptyError)setErrors(prev => ({
      ...prev,
      versionError: { error: true, errorMsg: "Selecting a version is required" }
    }));
    else if (deviceIdEmptyError)setErrors(prev => ({
      ...prev,
      deviceIdError: { error: true, errorMsg: "Selecting at least one device is required" }
    }));
    if(!productTypeEmptyError&&!versionEmptyError&&!deviceIdEmptyError){
      if (step==0 &&changeInDeviceIds){
      getRemoteConfiguration();
      }
    setStep(prevStep => 
      Math.min(prevStep + 1, STEPS.length - 1)
    );}}
    else {
     
           
    const updatedDetailsArray = updateDetailsArrayErrors(detailsArray, "Network & Communication");

    setDetailsArray(updatedDetailsArray);

    const hasErrors = updatedDetailsArray.some(detail => detail.parameterCategory=="Network & Communication"&&detail.errorMsg && detail.errorMsg.length > 0);

          if (!hasErrors)    setStep(prevStep => 
            Math.min(prevStep + 1, STEPS.length - 1)
          );
    
    }
  }

  function decreaseStep() {
    if (step === 0) {
      // resetFormFields();
      history.push(`/`)
    } else {

      setStep(prevStep => 
        Math.max(prevStep - 1, 0)
      );
    }
  }
  const handleOpenPopup = () => {
    setOpenPopup(true);
  };
  const handleNoUpdate=()=>{
    history.push('/')
    setOpenPopup(false);

  }
  function handleSubmit() {
    const {versionNum} =selectedProductType;
    const productType=selectedProductType.productName;
    const uId = localStorage.getItem("USER_ID");
    
    const updatedDetailsArray1 = updateDetailsArrayErrors(detailsArray, "Topic Format & Interval");
    const updatedDetailsArray2 = updateDetailsArrayErrors(updatedDetailsArray1, "Message Format");

    setDetailsArray(updatedDetailsArray2);

    const hasErrors = updatedDetailsArray2.some(detail => detail.errorMsg && detail.errorMsg.length > 0);
  const remoteConfigUpdateList=detailsArray.filter(item => item.checkUpdate)  
  .map(({ checkUpdate,errorMsg, ...item }) => ({
    ...item,
    defaultValue: item.defaultValue,
  }));

   if(!hasErrors && remoteConfigUpdateList.length>0){
    if (selectedDeviceIds.length==1 ){
    const data = {
      productType,
      versionNum,
      remoteConfigurations: remoteConfigUpdateList,
    }
    setLoading(true);
     
    axios.put(`${environment.host}/core/device/deviceParameterConfiguration/${selectedDeviceIds[0]}/user/${uId}`, data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if ( response.data.message==="Success"  ){
          setToaster(true);
          setMessage("Remote Configuration message Sent");
          setMsgSuccess(true);
          setTimeout(() => {
            setToaster(false);
            history.push('/')
          }, 2000);
        }
        
      })
      .catch((error) => {   
        console.error(error);
        setLoading(false);
        setMessage(error.response.data.message);
        setMsgSuccess(false);
        setToaster(true);
        setTimeout(() => {
            setToaster(false); 
        }, 2000); 
                    });}
      else if (selectedDeviceIds.length>1){
        const dataBulk =[];
         selectedDeviceIds.forEach(device=>{
          dataBulk.push({device,
                    productType,
                    versionNum,
                    "remoteConfigurations": detailsArray
                    .filter(item => item.checkUpdate)  
                    .map(({ checkUpdate,errorMsg, ...item }) => ({
                      ...item,
                      defaultValue: item.defaultValue,
                    })),})
          })
        setLoading(true)
        axios.put(`${environment.host}/core/device/deviceParameterConfiguration/bulk/user/${uId}`, dataBulk,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
            },
          })
          .then((response) => {
            setLoading(false);
            
            if ( response.data.message==="Success" ){
              setToaster(true);
              const successfulDeviceIds = response.data.content
                 .filter(result => result.result === "Success") 
               .map(result => result.deviceId).join(','); 
               if (successfulDeviceIds.length>0){
              setMessage("device parameter configurations updated for devices:"+successfulDeviceIds);
              setMsgSuccess(true);
              setTimeout(() => {
                setToaster(false);
                history.push('/')
              }, 2000);
               }
               else{
                setToaster(true)
                setMessage("Error configuring devices");
                setMsgSuccess(false);
                setTimeout(() => {
                  setToaster(false);
                }, 2000);
               }
              
             
            }
           
          }
            
      )
          .catch((error) => {
            console.log(error);
            setLoading(false);
            setToaster(true);
            let msg=error.response.data.message;
            setMessage(msg?msg:"Error updateing configurations"); 
            setMsgSuccess(false);
            setTimeout(() => {
                setToaster(false); 
            }, 2000); 
        
          });
      }}
      else{
        handleOpenPopup();
      }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <Grid container alignItems="center" >
          <Grid item xs={12} md={12} style={{ textAlign: "center", paddingTop: "50px", paddingBottom: "40px" }}>
            <h4>Remote Manager</h4>
          </Grid>
        </Grid>

        <Grid container style={{textAlign: "center",paddingRight: "80px"}}>
        <Grid item xs={1} md={4}></Grid>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Grid item xs={2} md={1}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Circle number={index + 1} active={step.activate} completed={steps.findIndex(s => s.activate) > index}/>
                </Grid>
                <Grid item>
                  <Typography className={classes.circleText}
                    style={{
                      color: step.activate ? primaryBlue : primaryGray,
                    }}
                  >
                    {step.text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {index !== steps.length - 1 && (
              <Grid item xs={2} md={1}>
                <hr
                  style={{
                    marginTop: "32px",
                    opacity: steps[index + 1].activate ? "1" : "0.12",
                  }}
                  color={
                    steps[index + 1].activate ? primaryBlue : "rgba(112, 112, 112, .12)"
                  }
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>
      <Grid container className={classes.customContainer}>
      <Grid item className={classes.contentContainer}>
        {renderStepsComponent()}
      </Grid>
        
      <Grid item xs={12} md={12} className={classes.root}  style={{width:'100%'}}>
      <Grid container  justifyContent="flex-end" className="testing">
              {step !== 3 && (
                <Button
                  onClick={decreaseStep}
                  variant="contained"
                  className={classes.backButton}
                  style={{ marginRight: '10px' }}
                >
                  {step === 1 || step === 2 ? "Back" : "Cancel"}
                </Button>
              )}
              <Button
                onClick={step === 2 ? handleSubmit : increaseStep}
                variant="contained"
                className={`${classes.getStarted} ${classes.button}`}             
              >
                {step === 0 || step === 1 ? "Next" : "Done"}
              </Button>
</Grid>
</Grid>
</Grid>
        {loading &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80}/>
        </div>}
        <Snackbar
        open={toaster}
        autoHideDuration={6000}
        onClose={handleCloseToaster}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert
          onClose={handleCloseToaster}
          severity={msgSuccess === true ? "success" : "error"}
        >
          {message}
        </Alert>
      </Snackbar>
      <AlertPopup openDialog={openPopup} setOpenDialog={setOpenPopup} handleSkip={handleNoUpdate} textYes={"Ok"}
                textNo={"No"} description={"No changes have been made to the parameters. No remote configuration messages will be executed."} title={"No changes made"} />
      </div>
    </ThemeProvider>
  );
}

const Circle = (props) => {
  const paddedNumber = (props.number).toString().padStart(2, '0');
  return (
    <div>
      <div
        style={{
          borderRadius: "50%",
          width: "65px",
          height: "65px",
          backgroundColor: props.completed ? primaryBlue : white,
          border: props.active ? "2px solid #2A7CED" : (props.completed ? `2px solid ${primaryBlue}` : "0.25px solid #8F8F8F"),
          textAlign: "center",
          paddingTop: "10px",
          boxShadow: "0px 4px 8px #0000001F",
        }}
      >
        <h5 style={{
          paddingTop: "10px",
          fontSize: "16px",
          color: props.active ? primaryBlue : (props.completed ? white : primaryGray)
        }}>
          {paddedNumber}
        </h5>
      </div>
    </div>
  );
};