import {
  Button,
  createTheme,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Snackbar,
  Theme,
  ThemeProvider,
  Tooltip,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import {useState} from "react";
import {environment} from "src/environments/environment";
import {primaryBlue, primaryGray, white} from "../../../components/colors";
import {EditIconTwo, QuestionIcon} from "src/components/Icons";
import {Alert} from "@material-ui/lab";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import axios from "axios";
import useStyles from "src/app/maintenance/assets/styles";

export function MQTTProtocol(props) {
  const {protocol, onSuccess, credentialsError, setClientId} = props;
  const classes = local();
  const style = useStyles();

  const [id, setID] = useState("");
  const [clientID, setClientID] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [publish_acl, setPublishAcl] = useState("");
  const [subscribe_acl, setSubscribeAcl] = useState("");
  const [showForm, setShowForm] = useState(true);
  const [editedValues, setEditedValues] = useState(null);
  const [toUpdate, setToUpdate] = useState(false);
  const [toEdit, setToEdit] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [toaster, setToaster] = useState(false);
  const [reqSuccess, setReqSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isFill, setIsFill] = useState(false);
  const [errors, setErrors] = useState({
    client_id: null,
    username: null,
    password: null,
    subscribe_acl: null,
    publish_acl: null
  })

  const aclFormat = ['[{"pattern": "D2S/SA/aaaaa",', '"max_qos": 1},', '{"pattern": "D2S/SA/wwwww",', '"max_qos": 1}]'];
  const generateAclFormatString = () => {
    return aclFormat.join(' ');
  };

  React.useEffect(() => {
    resetErrors();
  }, []);
  React.useEffect(() => {
    resetErrors();
  }, [protocol]);

  //Get Client
  const getClient = async (id) => {
    try {
    await axios({
      method: 'GET',
      url: `${environment.host}/core/vernemq-acl/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
      },
    })
      return true;
  
    } catch (error) {
        console.error('Error fetching client:', error.response.data.message);
        FormClear();
        setReqSuccess(false);
        setErrorMessage(error.response.data.message);
        setToaster(true);
        return false;
    }
  };

  const generateCredentials = (data) => {
    if (toUpdate) {
      // Update Protocol Credentials
      axios({
        method: 'PUT',
        url: `${environment.host}/core/vernemq-acl/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
        },
        data: JSON.stringify(data),
      })
        .then(response => {
          if (response.status == 200) {
            setReqSuccess(true);
            setErrorMessage('Credentials Updated Successfully');
            setToaster(true);
            resetFormFields();
            setShowForm(false);
            onSuccess(true);
          }
        })
        .catch(error => {
          console.error('Error updating HTTP credentials:', error);
          if (error.response.data.errorCode === 'E554') {
            setShowForm(true);
            setReqSuccess(false);
            setErrorMessage(error.response.data.message);
            setToaster(true);
            onSuccess(false);
          }
        });

    } else {
      // POST Protocol Credentials
      axios({
        method: 'POST',
        url: `${environment.host}/core/vernemq-acl`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
        },
        data: JSON.stringify(data)
      })
        .then(res => {
          const id = res.data.content.id;
          if (res.status === 200) {
            setReqSuccess(true);
            setErrorMessage('Credentials Generated Successfully');
            setToaster(true);
            onSuccess(true);
            setToEdit(true);
            setShowForm(false);
            setID(id);
          }
        })
        .catch(error => {
          console.error('Error generating MQTT credentials:', error);
          if (error.response.status == 422) {
            setReqSuccess(false);
            setErrorMessage(error.response.data.message);
            setToaster(true);  
            onSuccess(false);
          }
          FormClear();
        });
    }
  };

  const handleCloseToaster = () => {
    setToaster(false);
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const resetFormFields = () => {
      setClientID("");
      setUsername("");
      setPassword("");
      setPublishAcl("");
      setSubscribeAcl("");
    setIsFill(false);
    resetErrors();
  };

  const FormClear = () => {
    setToUpdate(false);
    setToEdit(false);
    setShowForm(true);
  };

  const resetErrors = () => {
    setErrors({
      client_id: null,
      username: null,
      password: null,
      subscribe_acl: null,
      publish_acl: null
    })
  };

  const validateForm = (values) => {
    let errors: any = {};
    if (!values.client_id) {
      errors.client_id = "Client ID is Required";
    }

    if (!values.username) {
      errors.username = "Username is Required";
    }

    if (!values.password) {
      errors.password = "Password is Required";
    }
    if (!values.subscribe_acl) {
      errors.subscribe_acl = "Subscribe ACL is Required"
    }
    if (!values.publish_acl) {
      errors.publish_acl = "Publish ACL is Required"
    }

    return errors;
  };

  const handleSave = async () => {

    const publishValue = publish_acl ? JSON.parse(publish_acl) : null
    const subscribeValue = subscribe_acl ? JSON.parse(subscribe_acl) : null
    const data = {
      username: username,
      client_id: clientID,
      password: password,
      publish_acl: publishValue,
      subscribe_acl: subscribeValue
    };
    const formValid = validateForm(data)
    setErrors(formValid)
    if (Object.keys(formValid).length > 0) {
      return;
    } else {
      const editedValues = {
        clientID,
        username,
        password,
        publish_acl,
        subscribe_acl
      };
      setEditedValues(editedValues);
      await generateCredentials(data);
      setClientId(id)
    }
  };
  const theme = createTheme({
    overrides: {
      MuiOutlinedInput: {
        notchedOutline: {
          borderColor: 'transparent',
        },
      },
    },
  });

  const handleEditClick = () => {
    getClient(id);
    setClientID(editedValues.clientID);
    setUsername(editedValues.username);
    setPassword(editedValues.password);
    setPublishAcl(editedValues.publish_acl);
    setSubscribeAcl(editedValues.subscribe_acl);
    setToUpdate(true);
    setIsFill(false);
    setShowForm(!showForm);
  };

  const jsonValidator = (input) => {
    try {
      JSON.parse(input);
      return null;
    } catch (error) {
      return true;
    }
  };
  const handleInputChange = (e) => {
    setIsFill(true);
    const {name, value} = e.target;
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
    if (name === 'client_id') {
      setClientID(value);
    } else if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    } else if (name == 'subscribe_acl' || name == 'publish_acl') {
      const isPublishAcl = name === "publish_acl";
      if (isPublishAcl) {
        setPublishAcl(value)
      } else {
        setSubscribeAcl(value)
      }

      if (value) {
        const validationError = jsonValidator(value);
        setErrors(prevErrors => ({
          ...prevErrors,
          [name]: validationError ? "Invalid JSON Format" : ""
        }));
      }

    }


  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container alignItems="center">
              <Typography className={style.typo}>
                Protocol
                <span className={style.required}>*</span>
              </Typography>
            <Grid item xs={12} md={12}>
              <FormControl className={classes.input}>
                <Typography className={style.formInput}>
                  {protocol}
                  {toEdit && (
                    <IconButton onClick={handleEditClick}>
                      <EditIconTwo/>
                    </IconButton>
                  )}
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      {showForm && (
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={12}>
            <Grid container alignItems="center">
              <Grid item xs={12} md={12}>
                  <FormControl className={style.input}>
                    Client ID
                    <input
                      className={style.formInput}
                      type="text"
                      value={clientID}
                      name="client_id"
                      onChange={handleInputChange}
                      placeholder="Enter Client ID"
                    />
                    {errors.client_id && (
                      <span className="form-error">
                        {errors.client_id}
                      </span>
                    )}
                  </FormControl>
                  <Grid item
                        style={{
                          display: 'flex',
                          marginTop: '10px'
                        }}
                  >
                    <FormControl
                      className={style.input}
                      style={{marginRight: '10px'}}
                    >
                      Username
                      <input
                        className={style.formInput}
                        type="text"
                        value={username}
                        name="username"
                        onChange={handleInputChange}
                        placeholder="Enter Username"
                      />
                      {errors.username && (
                        <span className="form-error">
                          {errors.username}
                        </span>
                      )}
                    </FormControl>
                    <FormControl
                      className={style.input}
                      variant="outlined"
                    >
                      Password
                      <OutlinedInput
                        className={style.formInput}
                        style={{margin:"0px"}}
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        name="password"
                        onChange={handleInputChange}
                        placeholder="Enter Password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handlePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {errors.password && (
                        <span className="form-error">
                          {errors.password}
                        </span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item
                        style={{
                          display: 'flex',
                          marginTop: '10px'
                        }}>
                    <FormControl
                      className={classes.input}
                      style={{marginRight: '10px', position: 'relative'}}
                    >
                      <Typography className={style.typo}>
                        Publish Acl
                        &nbsp;
                        <Tooltip
                          arrow
                          title={
                            <React.Fragment>
                              <Grid container>
                                <Grid item xs={12} md={12}>
                                  <Typography
                                    style={{fontSize: "12px", color: '#FFFFFF', fontWeight: 300, fontFamily: 'poppins'}}
                                  >Acl format should be like this: <br/>
                                    {generateAclFormatString()}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          }
                          interactive
                          placement="right"
                        >
                         <IconButton style={{padding: "1px 2px 2px 0px"}}>
                            <QuestionIcon/>
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <input
                        style={{
                          height: "80px",
                          paddingBottom: "40px"
                        }}
                        className={style.formInput}
                        type="text"
                        name="publish_acl"
                        value={publish_acl}
                        onChange={handleInputChange}
                        placeholder="Enter Publish ACL"
                      />
                      {errors.publish_acl && (
                        <span className="form-error">
                          {errors.publish_acl}
                        </span>
                      )}
                    </FormControl>
                    <FormControl
                      className={classes.input}
                    >
                      <Typography className={style.typo}>
                        Subscribe Acl
                        &nbsp;
                        <Tooltip
                          arrow
                          title={
                            <React.Fragment>
                              <Grid container>
                                <Grid item xs={12} md={12}>
                                  <Typography
                                    style={{fontSize: "12px", color: '#FFFFFF', fontWeight: 300, fontFamily: 'poppins'}}
                                  >Acl format should be like this: <br/>
                                    {generateAclFormatString()}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          }
                          interactive
                          placement="right"
                        >
                           <IconButton style={{padding: "1px 2px 2px 0px"}}>
                            <QuestionIcon/>
                          </IconButton>
                        </Tooltip>
                      </Typography>
                      <input
                        style={{
                          height: "80px",
                          paddingBottom: "40px"
                        }}
                        className={style.formInput}
                        type="text"
                        name="subscribe_acl"
                        value={subscribe_acl}
                        onChange={handleInputChange}
                        placeholder="Enter Subscribe ACL"
                      />
                      {errors.subscribe_acl && (
                        <span className="form-error">
                          {errors.subscribe_acl}
                        </span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item container className={style.protocolButton}>
                        <Button variant='contained'
                                className={style.backButton}
                                onClick={resetFormFields}
                        >
                          Clear
                        </Button>
                      <Button variant='contained' className={style.saveButton}
                              style={{marginRight:"0px"}}
                              onClick={handleSave}
                              disabled={!isFill}
                      >
                        {toUpdate ? "Update" : "Add"}
                      </Button>
                  </Grid>
                  {credentialsError && (
                    <span className="form-error">
                      Add Credentials is Required
                    </span>
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={3}></Grid>
        </Grid>
      )}
      <Snackbar
        open={toaster}
        autoHideDuration={6000}
        onClose={handleCloseToaster}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert
          onClose={handleCloseToaster}
          severity={reqSuccess === true ? "success" : "error"}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};


const local = makeStyles((theme: Theme) => ({
  input: {
    width: "100%",
  },
  label: {
    minWidth: 150,
    textAlign: 'left',
  },

}));
