import {Button, CircularProgress, Dialog, DialogActions, FormControl, Grid, IconButton, makeStyles, Theme, Typography,} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import * as React from "react";
import {useState} from "react";
import {primaryBlue, textBarColor} from "src/components/colors";
import {TextBar} from "src/components/sub-components/TextBar";
import {environment} from "src/environments/environment";
import {CloseIcon, CloseIconColor} from "../../../components/Icons";
import axios from "axios";
import useStyles from "src/app/maintenance/assets/styles";
import { AccountService } from "src/app/account/account.service";
import { AuthService } from "src/app/authentication/auth.service";
import validateTopics from "./validateTopicFormat";

export function ViewDeviceConfig(props) {
  const classes = useLocalStyles();
  const style=useStyles()
  const deviceData =  props.device.device;
  const productName=props.productType;
  const { 
    name,
    id,
    remoteConfigTopic,
    remoteConfigAckTopic,
    otaAckTopic,
    otaRequestTopic,
    otaConfigured,
    rmConfigured,
    productType
   } = deviceData;


 const [updatedotaAckTopic,setupdatedotaAckTopic]=useState(otaAckTopic||"");
 const [updatedrmAckTopic,setupdatedrmAckTopic]=useState(remoteConfigAckTopic||"")
 const [updatedotaRequestTopic,setupdatedotaRequestTopic]=useState(otaRequestTopic||"")
 const [updatedrmConfigTopic,setupdatedrmConfigTopic]=useState(remoteConfigTopic||"")
 const [loadingScreen, setLoadingScreen] = useState(false);
 
 const [errors,setErrors]=useState({
  rmAckError:"",
  rmConfigError:"",
  otaAckError:"",
  otaError:"",
  mismatchedIdError:""
 });


   
  const updateDeviceTopic=async()=>{

    const accessToken = localStorage.getItem('ACCESS_TOKEN');

    
    const updatedDeviceData={
      otaAckTopic:updatedotaAckTopic,
      otaRequestTopic:updatedotaRequestTopic,
      remoteConfigTopic:updatedrmConfigTopic,
      remoteConfigAckTopic:updatedrmAckTopic
    }

    const errorsUpdated= validateTopics(props.rmToggled,props.otaToggled,updatedDeviceData,id,name);
    if(errorsUpdated.topicError||errorsUpdated.mismatcheUniqueIdError){
      let errorMsgs={
        otaError:errorsUpdated.OTAUpgradableTopicError?"this input is required":errorsUpdated.OTARequestFormatError?"not in the format ../--/{id or name}/.../#":"",
        otaAckError:errorsUpdated.OTAAckTopicError?"this input is required":errorsUpdated.OTAAckFormatError?"not in the format ../--/{id or name}":"",
        rmConfigError:errorsUpdated.RemoteConfigTopicError?"this input is required":errorsUpdated.RemoteConfigFormatError?"not in the format ../--/{id or name}/.../#":"",
        rmAckError:errorsUpdated.RemoteConfigAckTopicError?"this input is required":errorsUpdated.RemoteConfigAckFormatError?"not in the format ../--/{id or name}/.../#":"",
        mismatchedIdError:errorsUpdated.mismatcheUniqueIdError?"Included unique id should be same in all topics":""}
      setErrors(errorMsgs);
      return {error:true};
      }

      
    try{
      setLoadingScreen(true); 
    const response=await axios.patch(`${environment.host}/core/device/${deviceData.id}`,updatedDeviceData, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      }});
    if (response.data.message){
      await props.fetchDevices(productName);
      props.setReqSuccess(true);
      props.setOpenAlert(true);
      setTimeout(() => {
        props.setOpenAlert(false);
      }, 4000);
      
      return {error:false};
    }
    }
    catch(error){
          setLoadingScreen(false);
          props.setReqSuccess(false);
          props.setErrorMessage(error.data.message?error.data.message:"Something went wrong");
          setTimeout(() => {
            props.setOpen(false);
            props.setErrorMessage("");
          }, 4000);
        } 
          

      
    
  }
  const handleUpdate = async () => {
    let isError = false;

    
    try {

      const result=await updateDeviceTopic();
      if(!result.error){
        props.onClose();
 }

    } catch (error) {
      props.setReqSuccess(false);
          props.setErrorMessage(error.data.message||"Error");
          setTimeout(() => {
            props.setOpen(false);
            props.setErrorMessage("");
          }, 6000);
    }
  };


  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        fullWidth={true}
        maxWidth={"md"}
        BackdropProps={{
          style: {
            backgroundColor: "cubic-bezier(0.4, 0, 0.1, 1)",
            opacity: props.opacity,
          },
        }}
        PaperProps={{
          style: {
            boxShadow: "inherit",
          },
        }}
      >
        <Grid container
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                opacity: "1",
                boxShadow: "0px 4px 30px #00000008",
              }}
        >
            <DialogTitle>Edit Device :{props.device.name} </DialogTitle>

            <IconButton onClick={props.onClose}>
              <CloseIconColor/>
            </IconButton>
          </Grid>
        
        <Divider/>
        <>
            <Grid container className={classes.container} >
              
              {(otaConfigured!="N/A" || props.type=="view" )&&
              <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={6} md={6}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                OTA Config Topic:
                </Typography>
              <FormControl className={style.input}>
                    <input
                      className={`${style.formInput} ${props.type === "view" ? classes.disabledInput : ''}`}
                      disabled={props.type === "view" }
                      onChange={(e) => {
                        setupdatedotaRequestTopic(e.target.value);
                      }}
                      value={
                        otaConfigured=="N/A"?otaConfigured:updatedotaRequestTopic
                      }
                    />
                  </FormControl>
                  {errors.otaError.length>0 && (
                    <Typography className={style.errorText}>
                      {errors.otaError}</Typography>
                  )}
              </Grid>
              <Grid item xs={6} md={6}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                OTA Config Ack Topic:
                </Typography>
  
                <FormControl className={style.input}>
                    <input
                      className={`${style.formInput} ${props.type === "view" ? classes.disabledInput : ''}`}
                      disabled={props.type === "view" }
                      onChange={(e) => {
                        setupdatedotaAckTopic(e.target.value);
                      }}
                      value={
                        otaConfigured=="N/A"?otaConfigured:updatedotaAckTopic
                      }
                    />
                  </FormControl>
                  {errors.otaAckError.length>0 && (
                    <Typography className={style.errorText}>
                      {errors.otaAckError}</Typography>
                  )}
              </Grid>
              </Grid>}
              {rmConfigured!="N/A"|| props.type=="view" ?
              <Grid container alignItems="center" justifyContent="center">
              <Grid item xs={6} md={6}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                Remote Config Topic:
                </Typography>

                <FormControl className={style.input}>
                    <input
                      className={`${style.formInput} ${props.type === "view" ? classes.disabledInput : ''}`}
                      disabled={props.type === "view" }
                      onChange={(e) => {
                        setupdatedrmConfigTopic(e.target.value);
                      }}
                      value={
                        rmConfigured=="N/A"?rmConfigured:updatedrmConfigTopic
                      }
                    />
                  </FormControl>
                  {errors.rmConfigError.length>0 && (
                    <Typography className={style.errorText}>
                      {errors.rmConfigError}</Typography>
                  )}
              </Grid>
              <Grid item xs={6} md={6}
                    className={classes.gridStyle}
              >
                <Typography className={classes.labelStyle}>
                Remote Config Ack Topic:
                </Typography>
                <FormControl className={style.input}>
                    <input
                      className={`${style.formInput} ${props.type === "view" ? classes.disabledInput : ''}`}
                      disabled={props.type === "view" }
                      onChange={(e) => {
                        setupdatedrmAckTopic(e.target.value);
                      }}
                      value={
                        rmConfigured=="N/A"?rmConfigured:updatedrmAckTopic
                      }
                    />
                  </FormControl>
                  {errors.rmAckError.length>0 && (
                    <Typography className={style.errorText}>
                      {errors.rmAckError}</Typography>
                  )}
              </Grid>
            </Grid>:""}
            </Grid>
            <Grid item xs={12} md={12} > 
              <Typography className={style.errorText}>
            {errors.mismatchedIdError}</Typography> </Grid>
            {(props.type=="edit")?
            <Grid container className={classes.container} justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                style={{
                  color: 'white',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  backgroundColor: primaryBlue,
                }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </Grid>:""}
                  {loadingScreen &&
                    <div style={{
                      position: 'fixed',
                      left: 0,
                      top: 0,
                      backgroundColor: '#000000',
                      opacity: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: 9999,
                      width: '100vw',
                      height: '100vh'
                    }}>
                      <CircularProgress size={80} />
                    </div>}
        </>
      </Dialog>
    </div>
  );
};


const useLocalStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px",
    width:"100%",
    padding:"10px"
  },
  gridStyle: {
    textAlign: "left",
    marginBottom: "25px",
    padding: "2%",
    justifyContent:"center"
  },
  labelStyle: {
    fontFamily: "Poppins",
    fontSize: "15px",
    lineHeight: "25px",
  },
  textBarStyle: {
    backgroundColor: textBarColor,
    height: "38px",
    borderRadius: "5px",
    border: "1px solid black",
  },
  disabledInput:{
    border: "none",
    color: "black",
    cursor: "not-allowed",
  }
}));
