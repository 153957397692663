import { Button, createStyles, Divider, Grid, IconButton, makeStyles, Snackbar, Theme, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { primaryBlue, textBarColor, white } from "src/components/colors";
import { AddIconTwo, DeleteIcon, EditIconTwo, ExpandIconTwo, ViewIconTwo } from "src/components/Icons";





export function RemoteConfigComponent  (props)  {
  const classes=useStyles();
  return (
    <Grid container >
      <Grid
        item
        xs={12}
        md={12}
        style={{
          textAlign: "left",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",

        }}
      >{props.parametersAddedError && props.parameters.length == 0 ?
        <Typography className={classes.helperText}> Remote Config Parameters are Required *</Typography> : ""}
      </Grid>


      <Grid
        item
        xs={5}
        md={5}
        style={{ textAlign: "left", paddingTop: "20px" }}
      >
        <Typography style={{ color: "#324054A6" }}>
          Network & Communication
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={6}
        style={{ textAlign: "left", paddingTop: "20px" }}
      >
        <Divider style={{ marginTop: "12px", width: "100%" }} />
      </Grid>
      <Grid item xs={1} md={1} style={{ textAlign: "left", paddingTop: "5px" }}>
        <IconButton onClick={() => props.openNetworkVisibility()}>
          <div className={classes.subIconButtonDiv}>
            <ExpandIconTwo rotation={props.networkVisibility} />
          </div>
        </IconButton>
      </Grid>

      {props.networkVisibility ? (
        <Grid container>
          {props.editparameterfields ?
            <Grid item xs={12} md={12} style={{ textAlign: "right", paddingRight: "40px" }}>

              <Button
                className={classes.addButton}
                variant="outlined"
                onClick={() =>
                  props.open("Network & Communication", "add-new", -1)
                }
                startIcon={<AddIconTwo />}
              >
                ADD
              </Button>
            </Grid> : ""}
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Network & Communication"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{
              textAlign: "left",
              paddingTop: "25px",
              paddingLeft: "10px",
              paddingBottom: "20px",
            }}
          >
            <Typography className={classes.topic}>PARAMETERS</Typography>
          </Grid>
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Network & Communication"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{ textAlign: "center", paddingTop: "25px" }}
          >
            <Typography className={classes.topic}>ACTION</Typography>
          </Grid>
          {props.parameters.map((parameter, i) => {
            return (
              parameter.parameterCategory === "Network & Communication" && (
                <Grid
                  container
                  key={i}
                  style={{
                    backgroundColor:
                      i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                    textAlign: "left",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid item xs={6} md={6}>
                    <Typography style={{ paddingLeft: "10px" }}>
                      {parameter.parameter}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ textAlign: "center" }}>
                    <IconButton
                      style={{ padding: "0px" }}
                      onClick={() =>
                        props.open("Network & Communication", "view", i)
                      }
                    >
                      <ViewIconTwo />
                    </IconButton>
                    {props.editparameterfields ?
                      <IconButton
                        style={{ marginLeft: "10px", padding: "0px" }}
                        onClick={() =>
                          props.open("Network & Communication", "edit", i)
                        }
                      >
                        <EditIconTwo />
                      </IconButton> : ""}
                    {props.editparameterfields ?
                      <IconButton
                        style={{ marginLeft: "10px", padding: "0px" }}
                        onClick={() =>
                          props.open("Network & Communication", "delete", i)
                        }
                      >
                        <DeleteIcon />
                      </IconButton> : ""}
                  </Grid>
                </Grid>
              )
            );
          })}
        </Grid>
      ) : (
        <></>
      )}

      <Grid
        item
        xs={5}
        md={5}
        style={{ textAlign: "left", paddingTop: "20px" }}
      >
        <Typography style={{ color: "#324054A6" }}>
          Topic Format & Interval
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={6}
        style={{ textAlign: "left", paddingTop: "20px" }}
      >
        <Divider style={{ marginTop: "12px", width: "100%" }} />
      </Grid>
      <Grid item xs={1} md={1} style={{ textAlign: "left", paddingTop: "5px" }}>
        <IconButton onClick={() => props.openTopicVisibility()}>
          <div className={classes.subIconButtonDiv}>
            <ExpandIconTwo rotation={props.topicVisibility} />
          </div>
        </IconButton>
      </Grid>

      {props.topicVisibility ? (
        <Grid container>
          {props.editparameterfields ?
            <Grid item xs={12} md={12} style={{ textAlign: "right", paddingRight: "40px" }}>
              <Button
                className={classes.addButton}
                variant="outlined"
                onClick={() =>
                  props.open("Topic Format & Interval", "add-new", -1)
                }
                startIcon={<AddIconTwo />}
              >
                ADD
              </Button>
            </Grid> : ""}
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Topic Format & Interval"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{
              textAlign: "left",
              paddingTop: "25px",
              paddingLeft: "10px",
              paddingBottom: "20px",
            }}
          >
            <Typography className={classes.topic}>PARAMETERS</Typography>
          </Grid>
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Topic Format & Interval"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{ textAlign: "center", paddingTop: "25px" }}
          >
            <Typography className={classes.topic}>ACTION</Typography>
          </Grid>
          {props.parameters.map((parameter, i) => {
            return (
              parameter.parameterCategory === "Topic Format & Interval" && (
                <Grid
                  container
                  key={i}
                  style={{
                    backgroundColor:
                      i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                    textAlign: "left",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid item xs={6} md={6}>
                    <Typography style={{ paddingLeft: "10px" }}>
                      {parameter.parameter}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ textAlign: "center" }}>
                    <IconButton
                      style={{ padding: "0px" }}
                      onClick={() =>
                        props.open("Topic Format & Interval", "view", i)
                      }
                    >
                      <ViewIconTwo />
                    </IconButton>
                    {props.editparameterfields ?
                      <IconButton
                        style={{ marginLeft: "10px", padding: "0px" }}
                        onClick={() =>
                          props.open("Topic Format & Interval", "edit", i)
                        }
                      >
                        <EditIconTwo />
                      </IconButton> : ""}
                    {props.editparameterfields ?
                      <IconButton
                        style={{ marginLeft: "10px", padding: "0px" }}
                        onClick={() =>
                          props.open("Topic Format & Interval", "delete", i)
                        }
                      >
                        <DeleteIcon />
                      </IconButton> : ""}
                  </Grid>
                </Grid>
              )
            );
          })}
        </Grid>
      ) : (
        <></>
      )}

      <Grid
        item
        xs={5}
        md={5}
        style={{ textAlign: "left", paddingTop: "20px" }}
      >
        <Typography style={{ color: "#324054A6" }}>Message Format</Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={6}
        style={{ textAlign: "left", paddingTop: "20px" }}
      >
        <Divider style={{ marginTop: "12px", width: "100%" }} />
      </Grid>
      <Grid item xs={1} md={1} style={{ textAlign: "left", paddingTop: "5px" }}>
        <IconButton onClick={() => props.openMessageVisibility()}>
          <div className={classes.subIconButtonDiv}>
            <ExpandIconTwo rotation={props.messageVisibility} />
          </div>
        </IconButton>
      </Grid>

      {props.messageVisibility ? (
        <Grid container>
          {props.editparameterfields ?
            <Grid item xs={12} md={12} style={{ textAlign: "right", paddingRight: "40px" }}>
              <Button
                className={classes.addButton}
                variant="outlined"
                onClick={() => props.open("Message Format", "add-new", -1)}
                startIcon={<AddIconTwo />}
              >
                ADD
              </Button>
            </Grid> : ""}
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "Message Format"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{
              textAlign: "left",
              paddingTop: "25px",
              paddingLeft: "10px",
              paddingBottom: "20px",
            }}
          >
            <Typography className={classes.topic}>PARAMETERS</Typography>
          </Grid>
          <Grid
            hidden={
              props.parameters.filter(
                (p) => p.parameterCategory === "MessageFormat"
              ).length > 0
                ? false
                : true
            }
            item
            xs={6}
            md={6}
            style={{ textAlign: "center", paddingTop: "25px" }}
          >
            <Typography className={classes.topic}>ACTION</Typography>
          </Grid>
          {props.parameters.map((parameter, i) => {
            return (
              parameter.parameterCategory === "Message Format" && (
                <Grid
                  container
                  key={i}
                  style={{
                    backgroundColor:
                      i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                    textAlign: "left",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                  <Grid item xs={6} md={6}>
                    <Typography style={{ paddingLeft: "10px" }}>
                      {parameter.parameter}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={6} style={{ textAlign: "center" }}>
                    <IconButton
                      style={{ padding: "0px" }}
                      onClick={() => props.open("Message Format", "view", i)}
                    >
                      <ViewIconTwo />
                    </IconButton>
                    {props.editparameterfields ?
                      <IconButton
                        style={{ marginLeft: "10px", padding: "0px" }}
                        onClick={() => props.open("Message Format", "edit", i)}
                      >
                        <EditIconTwo />
                      </IconButton> : ""}
                    {props.editparameterfields ?
                      <IconButton
                        style={{ marginLeft: "10px", padding: "0px" }}
                        onClick={() =>
                          props.open("Message Format", "delete", i)
                        }
                      >
                        <DeleteIcon />
                      </IconButton> : ""}
                  </Grid>
                </Grid>
              )
            );
          })}
        </Grid>
      ) : (
        <></>
      )}
      
    </Grid>)
}

const useStyles = makeStyles((themes: Theme) =>
  createStyles({
    container: {
      paddingTop: "20px",
    },
    clone: {
      fontSize: "14px",
    },
    subIconButtonDiv: {
      backgroundColor: "#F5F5F5",
      width: "25px",
      height: "25px",
      borderRadius: "100%",
      textAlign: "center",
    },
    topic: {
      fontWeight: "bold",
      fontSize: "14px",
    },
    addButton: {
      backgroundColor: white,
      color: primaryBlue,
      borderColor: primaryBlue,
    },
    clonedAlready: {
      color: "blue",
      fontSize: "14px",
      marginTop: "10px",
      fontWeight: "bold",
      border: "2px solid blue",
      padding: "8px",
      backgroundColor: "lightblue",
    },
    clonedIndicator: {
      display: "flex",
      alignItems: "center",
      marginTop: "10px",
      fontWeight: "bold",
      backgroundColor: "lightblue",
      padding: "8px",
      borderRadius: "5px",
    },
    clonedIcon: {
      color: "green",
      marginRight: "5px",
    },
    errorText: {
      color: "red",
      fontSize: "12px",
    },
    clonedText: {
      fontSize: "14px",
      color: "blue",
    },
    cloneButtonHover: {
      backgroundColor: primaryBlue,
      color: "white",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
    },
    helperText: {
      color: "red",
    },
    input: {
      width: "100%",
      height: "40px",
      backgroundColor: textBarColor,
    },
    label: {
      color: "#324054A6",
      paddingBottom: "5px",
    },
  })
);




