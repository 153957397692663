import * as React from "react";
import {useEffect, useState} from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import HelpInfo from '@material-ui/icons/InfoOutlined';
import {createStyles, makeStyles, Theme,alpha} from '@material-ui/core/styles';
import {primaryBlue, primaryGray, primaryRed} from 'src/components/colors';
import Dropzone from 'react-dropzone';
import {DragDrop} from 'src/components/sub-components/DragAndDrop';
import 'react-toastify/dist/ReactToastify.css';
import {environment} from "src/environments/environment";
import {CloseIconColor, DeleteIcon, DownloadIcon} from "src/components/Icons";
import axios from "axios";
import useStyles from "src/app/maintenance/assets/styles";


export function AddNewDecoder(props) {
  const style = useStyles();
  const classes = localStyles();
  const [name, setName] = useState('');
  const [decoderScriptFileName, setDecoderScriptFileName] = useState('');
  const [encoderScriptFileName, setEncoderScriptFileName] = useState('');
  const [selectedDecoderScript, setSelectedDecoderScript] = useState();
  const [selectedEncoderScript, setSelectedEncoderScript] = useState();
  const [codecNameExistError, setCodecNameExistError] = useState(false);
  const [selectedDataFormat, setSelectedDataFormat] = useState('');
  const [formatError, setFormatError] = useState(false);
  const [isExistData, setIsExistData] = useState(false);
  const [updateDataFormat, setUpdateDataFormat] = useState('');
  const [errors, setErrors] = useState({
    nameError: null,
    formatError: null,
    encoderFileError: null,
    decoderFileError: null
  })

  const closePopup = () => {
    setIsExistData(false)
    props.onClose();
    setErrors(prevError => ({
      ...prevError,
      decoderFileError: null,
      encoderFileError: null,
      nameError: null,
      formatError: null
    }))
    setFormatError(false)
    setName('');
    setDecoderScriptFileName('');
    setEncoderScriptFileName('')
    setSelectedDecoderScript(null)
    setSelectedEncoderScript(null)
    setSelectedDataFormat('')
  }

  const readDecoderScript = (decoderFile) => {
    setDecoderScriptFileName(decoderFile[0].name)
    setSelectedDecoderScript(decoderFile[0])
    setErrors(prevError => ({
      ...prevError,
      decoderFileError: null
    }))
  }

  const readEncoderScript = (encoderFile) => {
    setEncoderScriptFileName(encoderFile[0].name)
    setSelectedEncoderScript(encoderFile[0])
    setErrors(prevError => ({
      ...prevError,
      encoderFileError: null
    }))
  }

  const changeFile = (fileContent, name) => {
    var fileContent = fileContent
    var fileName = name
    var blob = new Blob([fileContent], {
      type: ""
    });
    var file = new File([blob], fileName, {type: ""})
    return file
  }


  const createDecoder = () => {
    props.setLoading(true);

    const formData = new FormData();
    formData.append('decoderFile', selectedDecoderScript ? selectedDecoderScript : new File([''], 'empty.txt', {type: 'text/plain'}));
    formData.append('encoderFile', selectedEncoderScript ? selectedEncoderScript : new File([''], 'empty.txt', {type: 'text/plain'}));
    formData.append('codecName', name);
    formData.append('scriptFormat', selectedDataFormat);

    axios.post(
      `${environment.host}/core/codec`, formData, {
        headers: {Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')},
      })
      .then((response) => {
        props.setToast(true);
        props.setLoading(false);
        props.getAllCodec();
        props.setReqSuccess(true);
        props.setErrorMessage('Codec Added Successfully');
        props.onClose(true);
        closePopup();
      })
      .catch((error) => {
        props.setToast(true);
          props.setLoading(false);
          props.setReqSuccess(false);
          props.setErrorMessage(error.response.data.message);
      })
      .finally(() => {
        setTimeout(() => {
          props.setToast(false);
        }, 4000);
      })
  };

  const updateDecoder = () => {
    props.setLoading(true);

    const formData = new FormData();
    formData.append('decoderFile', decoderScriptFileName == '' ? new File([''], 'empty.txt', {type: 'text/plain'}) : changeFile(selectedDecoderScript, decoderScriptFileName));
    formData.append('encoderFile', encoderScriptFileName == '' ? new File([''], 'empty.txt', {type: 'text/plain'}) : changeFile(selectedEncoderScript, encoderScriptFileName));
    formData.append('codecName', props.type === 'update' && name === '' ? props.codecName : name);
    formData.append('scriptFormat', props.type === 'update' ? updateDataFormat : selectedDataFormat);
    axios.put(
      `${environment.host}/core/codec/${props.decoderId}`, formData,
      {
        headers: {Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')}
      })
      .then((response) => {
        props.setToast(true);
          props.setLoading(false);
          props.getAllCodec();
          props.setReqSuccess(true);
          props.setErrorMessage('Codec Updated Successfully');
          props.onClose(true);
          closePopup();
      })
      .catch((error) => {
        props.setLoading(false);
        props.setReqSuccess(false);
        props.setErrorMessage(error.response.data.message);
      })
      .finally(() => {
        setTimeout(() => {
          props.setToast(false);
        }, 4000);
      })
  };

  const readParameters = () => {
    let error: any = {}
    if (props.type !== 'update') {
      if (name === '') {
        error.nameError = "Name Required *"

      } else if (props.decoderNames.includes(name)) {
        error.nameError = "Codec Name Already Exist *"
      }
      if (selectedDataFormat === '') {
        error.formatError = "Data Format Required *"

      }
      if (encoderScriptFileName === '' && decoderScriptFileName === '') {
        if (encoderScriptFileName === '') {
          error.encoderFileError = "Encoder File Required *"
        }
        if (decoderScriptFileName === '') {
          error.decoderFileError = "Decoder File Required *"
        }
      }


    } else {
      if (props.codecName !== name && props.decoderNames.includes(name)) {
        error.nameError = "Codec Name Already Exist *"
      } else { if ((encoderScriptFileName === '' || encoderScriptFileName === null) && (decoderScriptFileName === '' || decoderScriptFileName === null)) {
        if (encoderScriptFileName === '' ||  encoderScriptFileName === null) {
          error.encoderFileError = "Encoder File Required *"
        }
        if (decoderScriptFileName === '' || decoderScriptFileName === null) {
          error.decoderFileError = "Decoder File Required *"
        }
      }
      }
    }

    let allErrors = error;
    setErrors(allErrors)
    if (Object.keys(allErrors).length > 0) {
      return
    } else {
      if (props.type == 'update') {
        updateDecoder()
      } else {
        createDecoder()
      }
    }
  };

  useEffect(() => {
    if (props.type === 'update') {
      setUpdateDataFormat(props.scriptFormat);
      if (props.currentEncoderFileName != null){
      setEncoderScriptFileName(props.currentEncoderFileName)
      }
      if (props.currentDecoderFileName != null) {
        setDecoderScriptFileName(props.currentDecoderFileName)
      }
      if (props.decoderScript != null) {
        setSelectedDecoderScript(props.decoderScript)
      }
      if (props.encoderScript != null) {
        setSelectedEncoderScript(props.encoderScript)
    }} else {
      setSelectedDataFormat('');
      setEncoderScriptFileName('')
      setDecoderScriptFileName('')
      setSelectedDecoderScript(null)
      setSelectedEncoderScript(null)
    }
  }, [props.type, props.scriptFormat, props.currentEncoderFileName, props.currentDecoderFileName]);


  const NewTooltip = withStyles({
    tooltip: {
      color: "black",
      backgroundColor: "white",
      boxShadow: "0px 3px 6px #00000029",
      padding: "10px",
      fontSize: "12px",
    },
  })(Tooltip);

  const downloadCodecFile = async (fileType: 'encoder' | 'decoder') => {
    try {
      const response = await axios.get(`${environment.host}/core/codec/${props.decoderId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
        }
      });
  
      if (response.status === 200) {
        const codec = response.data.content;
  
        let fileContent, fileName;
  
        if (fileType === 'encoder') {
          fileContent = codec.encoderFileContent;
          fileName = codec.encoderFileName;
        } else if (fileType === 'decoder') {
          fileContent = codec.decoderFileContent;
          fileName = codec.decoderFileName;
        }
  
        if (fileContent && fileName) {
          const blob = new Blob([fileContent], { type: 'text/java' });
          
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
  
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(`Failed to download ${fileType} file: file content or name is missing`);
        }
      } else {
        console.error('Failed to fetch codec data');
      }
    } catch (error) {
      console.error(`Error fetching codec or downloading ${fileType} file:`, error);
    }
  };

  return (
    <Dialog open={props.open} className={classes.newDecoder} maxWidth={"xl"}
            classes={{paper: classes.customDialogPaper}}>
      <Grid container className={classes.header}>
        <Grid item xs={10} md={10}>
          <DialogTitle>{props.type === 'update' ? 'Edit Codec' : 'Create New Codec'}</DialogTitle>
        </Grid>
        <Grid item xs={2} md={2} style={{textAlign: "right"}}>
          <IconButton onClick={() => {
            closePopup()
          }}
                      style={{paddingTop: "20px"}}>
            <CloseIconColor/>
          </IconButton>
        </Grid>
      </Grid>
      {
        <DialogContent style={{margin: "0"}}>
          <Grid container>
            <Grid style={{textAlign: "left", paddingBottom: "20px", margin: "0"}}>
              <Typography className={classes.label}>Codec Name</Typography>
              <input
                type={'text'}
                className={classes.input}
                style={{
                  textIndent: "10px",
                  border: errors.nameError ? `0.5px solid ${primaryRed}` : '0.5px solid #C7C4C4'
                }}
                onChange={(e) => {
                  let nameValue = e.target.value
                  setName(e.target.value);
                  setErrors(prevError => ({
                    ...prevError,
                    nameError: null
                  }))
                  setCodecNameExistError(false);
                  if (props.type == 'update') {
                    if (nameValue == '') {
                      setErrors(prevError => ({
                        ...prevError,
                        nameError: "Name Required *"
                      }))
                    }
                    setIsExistData(true)
                  }

                }}

                defaultValue={props.type === 'update' ? props.codecName : name}
                placeholder={props.type === 'update' ? '' : "Enter codec name"}
                readOnly={false}
              />
              {errors.nameError && <FormHelperText className={classes.helperText}>{errors.nameError}</FormHelperText>}
            </Grid>
            <Grid style={{textAlign: 'left', paddingBottom: '20px', margin: '0'}}>
              <Typography className={classes.label}>Data Format</Typography>
              <RadioGroup
                value={props.type === 'update' ? updateDataFormat : selectedDataFormat}
                onChange={(e) => {
                  setSelectedDataFormat(e.target.value);
                  setErrors(prevError => ({
                    ...prevError,
                    formatError: null
                  }))
                  if (props.type == 'update') {
                    setUpdateDataFormat(e.target.value);
                    setIsExistData(true)
                  }
                }}
                style={{display: 'flex', flexDirection: 'row'}}
              >
                <div className={classes.customTypography}>
                <FormControlLabel
                  value="JSON"
                  control={<Radio className={style.radio}/>}
                  label="JSON"
                  style={{marginRight: '20px'}}
                />
                <FormControlLabel
                  value="HEX"
                  control={<Radio className={style.radio}/>}
                  label="HEX"
                  style={{marginRight: '20px'}}
                />
                </div>
                {/* Add more options as needed */}
              </RadioGroup>
              {errors.formatError && (
                <FormHelperText className={classes.helperText}>
                  {errors.formatError}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid  item md={12} xs={12}>
            <Grid style={{paddingBottom: "20px"}}>
              <Typography className={classes.label}>
                <NewTooltip title="Convert Magma Data into Device Data Format." placement="right" arrow>
                  <span className={style.tooltipCustom}>
                    Encoder
                    <HelpInfo style={{fontSize: 16, marginLeft: 5}}/>
                  </span>
                </NewTooltip>
              </Typography>
              <Grid item xs={12} md={12}>
                <Dropzone
                  onDrop={file => {
                    readEncoderScript(file);
                    if (props.type == 'update') {
                      setIsExistData(true)
                    }
                  }}>
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}
                           style={{
                             borderColor: (errors.encoderFileError && errors.decoderFileError) ? primaryRed : "#427AE5",
                             padding: "32px"
                           }}
                           className={style.dragDrop}>
                        <input {...getInputProps()} accept=".java"/>
                        <DragDrop/>
                          {(encoderScriptFileName != '' && encoderScriptFileName != null) ?
                              <div style={{textAlign: "center"}}>
                                <span style={{
                                  fontSize: "14px",
                                  color: "#707070",
                                  fontWeight: "500"
                                }}>{encoderScriptFileName}</span>
                                 {props.type === 'update' ?
                <NewTooltip title="Download Encoder File" placement="right" arrow>
                  <IconButton 
                    onClick={() => downloadCodecFile('encoder')}
                    style={{padding: "5px 8px 12px"}}>
                    <DownloadIcon />
                  </IconButton>
                </NewTooltip> 
                : 
                <></>
              }
                                    <IconButton 
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setSelectedEncoderScript(null),
                                        setEncoderScriptFileName('')
                                      }}
                                      style={{padding: "5px 5px 12px"}}>
                                      <DeleteIcon width={"16px"} height={"16px"}/>
                                    </IconButton>
                              </div>
                              :
                              <div style={{textAlign: "center"}}>
                                <span className={style.dragText}>
                                    Drag & Drop the Script
                                </span>
                              </div>
                            }
                          <div style={{textAlign:"center"}}>
                            <span className={style.dragTextSmall}
                            >{encoderScriptFileName != "" && encoderScriptFileName != null ? "" : "or Select the file from device"}</span>
                          </div>

                      </div>
                    </section>
                  )}
                </Dropzone>
                {errors.decoderFileError && errors.encoderFileError &&
                  <FormHelperText className={classes.helperText}>{errors.encoderFileError}</FormHelperText>}
              </Grid>
            </Grid>
          </Grid>
          <Grid  item md={12} xs={12}>
            <Grid style={{ paddingBottom: "20px"}}>
              <Typography className={classes.label}>
                <NewTooltip title="Convert Device Data into Magma Data Format." placement="right" arrow>
                  <span className={style.tooltipCustom}>
                    Decoder
                    <HelpInfo style={{fontSize: 16, marginLeft: 5}}/>
                  </span>
                </NewTooltip>
              </Typography>
              <Grid item xs={12} md={12}>
                <Dropzone
                  onDrop={file => {
                    readDecoderScript(file);
                    if (props.type == 'update') {
                      setIsExistData(true)
                    }
                  }}
                >
                  {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}
                           style={{
                             borderColor: (errors.encoderFileError && errors.decoderFileError) ? primaryRed : "#427AE5",
                             padding: "32px"
                           }}
                           className={style.dragDrop}>
                        <input {...getInputProps()} accept=".java"/>
                        <DragDrop/>
                        {(decoderScriptFileName != '' && decoderScriptFileName != null) ?
                              <div style={{textAlign: "center"}}>
                                <span style={{
                                  fontSize: "14px",
                                  color: "#707070",
                                  fontWeight: "500"
                                }}>{decoderScriptFileName}</span>
                                {props.type === 'update' ?
                <NewTooltip title="Download Decoder File" placement="right" arrow>
                  <IconButton 
                    onClick={() => downloadCodecFile('decoder')}
                    style={{padding: "5px 8px 12px"}}>
                    <DownloadIcon />
                  </IconButton>
                </NewTooltip>
                :
                <></>
              }
                                    <IconButton 
                                      onClick={(event) => {
                                      event.stopPropagation();
                                      setSelectedDecoderScript(null),
                                      setDecoderScriptFileName('')
                                    }}
                                    style={{padding: "5px 5px 12px"}}>
                                      <DeleteIcon width={"16px"} height={"16px"}/>
                                    </IconButton>
                              </div>
                              :
                              <div style={{textAlign: "center"}}>
                                <span className={style.dragText}>
                                    Drag & Drop the Script
                                </span>
                              </div>
                            }
                          <div style={{textAlign:"center"}}>
                            <span className={style.dragTextSmall}>{decoderScriptFileName != "" && decoderScriptFileName != null ? "" : "or Select the file from device"}</span>
                          </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {errors.encoderFileError && errors.decoderFileError &&
                  <FormHelperText className={classes.helperText}>{errors.decoderFileError}</FormHelperText>}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

      }
      {
        <DialogActions style={{width: "100%"}} hidden>
          <Button variant='contained' className={style.backButton} onClick={() => closePopup()}>
            Cancel
          </Button>
          <Button variant='contained' className={style.saveButton}
                  onClick={() => readParameters()}
          >
            {props.type === 'update' ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      }
      {props.loading &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80}/>
        </div>
      }
    </Dialog>
  )
}

export default AddNewDecoder

export const Circle = (props) => {
  return (
    <div>
      <div style={{
        borderRadius: "50%",
        width: "40px",
        height: "40px",
        backgroundColor: "white",
        border: props.active ? "2px solid #2A7CED" : "0.25px solid #8F8F8F",
        textAlign: "center",
        paddingTop: "10px",
        boxShadow: "0px 4px 8px #0000001F"
      }}>
        <h5 style={{color: props.active ? primaryBlue : primaryGray}}>{props.number}</h5>
      </div>
    </div>
  )
}


const localStyles = makeStyles((themes: Theme) =>
  createStyles({
    helperText: {
      color: "red"
    },
    label: {
      fontSize: "15px",
      color: primaryGray,
      paddingBottom: "5px"
    },
    input: {
      top: "219px",
      left: "361px",
      width: "717px",
      height: "44px",
      border: "0.5px solid #C7C4C4",
      borderRadius: "4px",
      opacity: 1,
      fontSize: "16px",
      letterSpacing: "0px",
      color: "#464646"
    },
    chip: {
      borderRadius: "4px",
      backgroundColor: "#C7C4C436",
      marginLeft: "5px"
    },
    newDecoder: {
      top: "106px",
      left: "337px",
      width: "766px",
      height: "auto",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      opacity: 1,
    },
    customDialogPaper: {
      margin: "0px"
    },
    header: {
      width: "766px",
      height: "60px",
      background: "#FFFFFF 0% 0% no-repeat padding-box",
      boxShadow: "0px 4px 30px #00000008",
      opacity: 1,
      font: "normal normal medium 18px/27px Poppins",
      letterSpacing: "0px",
      color: "#111111"
    },
    customTypography: {
      '& .MuiTypography-body1': {
        fontSize: '14px',
        fontFamily: 'Poppins, sans-serif',
      },
    }
  }),
);
