import {
  Button,
  Chip,
  CircularProgress,
  DialogActions,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { environment } from "src/environments/environment";
import { secondaryTextColor, white } from "src/components/colors";
import { SearchBar } from "src/components/sub-components/SearchBar";
import { DeleteBlackIcon, DeleteIconLarge, EditIconLarge, EditIconTwo, ViewIcon } from "src/components/Icons";
import { primaryBlue } from "src/components/colors";
import useStyles from "src/app/maintenance/assets/styles";
import { ViewDeviceConfig } from "./ViewDeviceConfig";
import { grey } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";


export function DevicesProductType(props) {
  const style = useStyles();
  const classes = useLocalStyles();
  const history = useHistory();
  const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
  });
  const [search, setSearch] = useState("");

  const [selectedDeviceConfig, setSelectedDeviceConfig] = useState()

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);

  const [reviewOpen, setReviewOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [open, setOpen] = useState(false);
  const [reqSuccess, setReqSuccess] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const rmConfigurableProd = props.rmToggled ? props.rmToggled : false;
  const otaConfigurableProd = props.otaToggled ? props.otaToggled : false;
  

  React.useEffect(()=>{
    const devicesMapped=props.mapDeviceData(props.devices);
    props.setDevices(devicesMapped);
  },[])

  const handleGoBack = () => {
    props.toggleDeviceView(false);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleReviewOpen = (props) => {
    setReviewOpen(true);
    setSelectedDeviceConfig(props);
  };
  const handleReviewClose = () => {
    setReviewOpen(false);
  };

  const handleEditOpen = (props) => {
    setEditOpen(true);
    setSelectedDeviceConfig(props);
  };
  const handleEditClose = () => {
    setEditOpen(false);
  };




  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <Grid container className={classes.container}>
          <Grid container>
            <Typography className={classes.headerText}>
              {props.productType.productName}
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={4} md={3}>
              <SearchBar placeholder="Search Device Name" onChange={(e) => setSearch(e.target.value)} />
            </Grid>
            <Grid item xs={4} md={3}></Grid>
            <Grid item xs={4} md={6}>
              <TablePagination
                rowsPerPageOptions={[6, 10, 25]}
                component="div"
                count={props.devices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{ marginTop: "20px", marginLeft: "10px" }}
          >
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          paddingLeft: "80px"
                        }}
                      >
                        DEVICE NAME
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        OTA upgradable
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        display="inline"
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        Remote Configurable
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        textAlign: "left",
                        borderBottomColor: "white",
                        borderTopColor: "white",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        ACTIONS
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {props.devices !== undefined &&
                    props.devices
                      .filter((obj) => {
                        if (search === "") {
                          return obj;
                        } else if (
                          obj.name
                            .toLowerCase()
                            .includes(search.trim().toLowerCase())
                        ) {
                          return obj;
                        }
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((device, i) => (
                        <TableRow key={i}>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <Typography
                              display="inline"
                              className={classes.tableText}
                              style={{ paddingLeft: "80px" }}
                            >
                              {device.name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            {device.otaConfigured &&
                              device.otaConfigured ===
                              "Configured" && (
                                <Chip
                                  className={style.greenChip}
                                  label="Configured"
                                />
                              )}
                            {device.otaConfigured &&
                              device.otaConfigured ===
                              "Not-Configured" && (
                                <Chip
                                  className={style.redChip}
                                  label="Not-Configured"
                                />
                              )}
                            {device.otaConfigured &&
                              device.otaConfigured ===
                              "N/A" && (
                                <Typography display="inline"
                                  className={classes.tableText}
                                  style={{
                                    paddingLeft: "5px",
                                    color: "grey"
                                  }}>
                                  N/A
                                </Typography>
                              )}
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            {device.rmConfigured &&
                              device.rmConfigured ===
                              "Configured" && (
                                <Chip
                                  className={classes.greenChip}
                                  label="Configured"
                                />
                              )}
                            {device.rmConfigured &&
                              device.rmConfigured ===
                              "Not-Configured" && (
                                <Chip
                                  className={classes.redChip}
                                  label="Not-Configured"
                                />
                              )}
                            {device.rmConfigured &&
                              device.rmConfigured ===
                              "N/A" && (
                                <Typography display="inline"
                                  className={classes.tableText}
                                  style={{
                                    paddingLeft: "5px",
                                    color: "grey"
                                  }}>
                                  N/A
                                </Typography>
                              )}

                          </TableCell>

                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor:
                                i % 2 === 0 || i / 2 === 0
                                  ? "#F9F9F9"
                                  : "white",
                            }}
                          >
                            <IconButton
                              className={style.iconStyle}
                              onClick={() =>
                                handleReviewOpen({
                                  device: device
                                })
                              }
                            >
                              <ViewIcon />
                            </IconButton>
                            <IconButton
                              className={style.iconStyle}
                              disabled={(!otaConfigurableProd && !rmConfigurableProd)}
                              onClick={() => {
                                handleEditOpen({
                                  device: device
                                });
                              }}
                            >
                              <EditIconLarge />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
              {props.devices !== undefined &&
              props.devices.length == 0 &&
              <Grid md={12}><Typography>
                No devices in the Selected Product type
              </Typography>
              </Grid>}
            </TableContainer>
            <Snackbar
              open={open}
              autoHideDuration={4000}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
      <Alert severity={reqSuccess === true ? "success" : "warning"}>
        <h3 style={{ textAlign: "left" }}>
          {reqSuccess === true ? "Success" : "Error"}
        </h3>
        {reqSuccess
          ? "Device Topics Updated Successfully"
          : errorMessage.length > 0
            ? errorMessage
            : "Something Went Wrong"}
            </Alert>
    </Snackbar>
          </Grid>

          {reviewOpen && (
            <ViewDeviceConfig
              device={selectedDeviceConfig}
              open={reviewOpen}
              onClose={handleReviewClose}
              type={"view"}
            />
          )}
          {editOpen &&
            <ViewDeviceConfig
              device={selectedDeviceConfig}
              open={editOpen}
              onClose={() => handleEditClose()}
              fetchDevices={props.fetchDevices}
              mapDeviceData={props.mapDeviceData}
              setDevices={props.setDevices}
              devices={props.devices}
              rmToggled={props.rmToggled}
              otaToggled={props.otaToggled}
              type={"edit"}
              setReqSuccess={setReqSuccess}
              setOpenAlert={setOpen}  
              setErrorMessage={setErrorMessage}
              productType={props.productType.productName} 
               
            />
          }

        </Grid>
        <DialogActions>
          <Button
            className={style.backButton}
            onClick={handleGoBack}
          >
            Back
          </Button>
        </DialogActions>
        {loadingScreen &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80} />
        </div>}
      </div>
    </ThemeProvider>
  );
}

const useLocalStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: "20px",
  },
  filter: {
    backgroundColor: white,
    color: secondaryTextColor,
    boxShadow: "none",
  },
  headerText: {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 600,
    paddingBottom:"20px",
    textTransform:"capitalize"
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  configuration: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  redIndicator: {
    backgroundColor: "#03C69F1F",
    color: "#03C69F",
    height: "20px",
  },
  greenIndicator: {
    backgroundColor: "#FC41411F",
    color: "#EE3939",
    height: "20px",
  },
  backButton: {
    color: grey[50],
    borderRadius: '8px',
    boxShadow: 'none',
    backgroundColor: white,
  },
  redChip: {
    backgroundColor: "#FC41411F",
    color: "#EE3939",
    height: "20px",
  },
  greenChip: {
    backgroundColor: "#03C69F1F",
    color: "#03C69F",
    height: "20px",
  },
}));

