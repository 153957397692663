import {alpha, makeStyles, Theme} from "@material-ui/core/styles";
import React, {useEffect, useRef, useState} from "react";
import {Button, CircularProgress, Grid, Typography,} from "@material-ui/core";
import {primaryBlue, primaryGray,white} from "src/components/colors";
import Step1ConnectDevice from "./steps/Step1ConnectDevice";
import {environment} from "src/environments/environment";
import Step2DeviceData from "./steps/Step2DeviceData";
import Step3ApiCall from "./steps/Step3ApiCall";
import {useHistory} from 'react-router-dom';
import {ToasterComponent} from "src/app/maintenance/test-automation/components/Toaster";
import axios from "axios";
import { RemoteConfig } from "src/components/Icons";
import useStyles from "src/app/maintenance/assets/styles";

const STEPS = [
  {
    text: "Connect Devices",
    activate: true,
  },
  {
    text: "Device Data",
    activate: false,
  },
  {
    text: "API Call",
    activate: false,
  },
];

export function ConnectDeviceComponent(props) {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState(STEPS);
  const [productType, setProductType] = useState("");
  const [productTypeId, setProductTypeId] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [interval, setInterval] = useState("");
  const [clientId, setClientId] = useState("")
  const [selectedProtocol, setSelectedProtocol] = useState("")
  const [otaRequestTopic, setOTATopic] = useState("");
  const [remoteConfigTopic, setRemoteConfigTopic] = useState("");
 // const [dataFormat, setDataFormat] = useState('');
  const [otaAckTopic, setOTAUpgradableTopic] = useState("");
  const [remoteConfigAckTopic, setRemoteConfigAckTopic] = useState("");
  const [formData, setFormData] = useState({
    protocol: "MQTT",
    interval: "",
  });
  const [nameError, setNameError] = useState(false);
  const [idError, setIdError] = useState(false);
  const [productTypeError, setProductTypeError] = useState(false);
  const [productTypeIdError, setProductTypeIdError] = useState(false);
  const [OTATopicError, setOTATopicError] = useState(false);
  const [OTAUpgradbleTopicError, setOTAUpgradbleTopicError] = useState(false);
  const [RemoteConfigTopicError, setRemoteConfigTopicError] = useState(false);
  const [RemoteConfigAckTopicError, setRemoteConfigAckTopicError] = useState(false);
 // const [dataFormatError, setDataFormatError] = useState(false);
  const [intervalError, setIntervalError] = useState(false);
  const [batchError, setBatchError] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState("");
  const [codecId, setCodecId] = useState("");
  const [addCredentialsSuccess, setAddCredentialsSuccess] = useState(false);
  const [credentialsError, setCredentialsError] = useState(false);
  const [otaUpgradableStatus, setOtaUpgradableStatus] = useState(false);
  const [RemoteConfigStatus, setRemoteConfigStatus] = useState(false);

  const [isToasterOpen, setIsToasterOpen] = useState(false)
  const [toasterMessage, setToasterMessage] = useState("")
  const [reqSuccess, setReqSuccess] = useState(false)
  const [formClear, setFormClear] = useState(false)
  const history = useHistory();
  const timeoutRef = useRef(null);

  const [OTAAckFormatError,setOTAAckFormatError]=useState({error:false ,errorMsg:""});
  const [OTARequestFormatError,setOTARequestFormatError]=useState({error:false ,errorMsg:""});
  const [remoteConfigFormatError,setRemoteConfigFormatError]=useState({error:false ,errorMsg:""});
  const [remoteConfigAckFormatError,setRemoteConfigAckFormatError]=useState({error:false ,errorMsg:""});
  const [notSameIdError,setnotSameIdError]=useState({error:false ,errorMsg:""});


 

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  useEffect(() => {
    updateStepActivation(step);
  }, [step]);

  function updateStepActivation(currentStep) {
    const updatedSteps = steps.map((step, index) => ({
      ...step,
      activate: index === currentStep,
    }));
    setSteps(updatedSteps);
  }
  
  const checkOTAAckTopicValidity = (text) => {
    const segments=text.split("/");
      if (segments[segments.length-1] === deviceId) {
        return { valid: true, IMEIId: true, name: false };
      } else if (segments[segments.length-1] === deviceName) {
        return { valid: true, IMEIId: false, name: true };
      }
    return {valid:false}
  }
  const checkTopicValidity = (text,position=null) => {

    const segments=text.split("/");
     if (position!=null &&segments.length>=position ){
      const identifier=segments[segments.length-position];
     if(identifier===deviceId||identifier==deviceName){
        return {
          valid:segments[segments.length-1]==="#",
          IMEIId:identifier===deviceId,
          name:identifier===deviceName};}
        }

     else {
      for (let segment of segments) {
        if (segment === deviceId) {
          return { valid: segments[segments.length-1]==="#", IMEIId: true, name: false };
        } else if (segment === deviceName) {
          return { valid: segments[segments.length-1]==="#", IMEIId: false, name: true };
        }
      }};
      return { valid: false };
     
    };
  const validateFormData =async () => {

    // Validation object to hold all errors
    const errors = {
      nameError: deviceName.trim() === "",
      idError: deviceId.trim() === "",
      productTypeError: productType.trim() === "",
      intervalError: formData.interval === "",
      batchError: selectedBatch.trim() === "",
      OTATopicError: false,
      OTAUpgradableTopicError: false,
      dataFormatError: false,
      RemoteConfigTopicError: false,
      RemoteConfigAckTopicError: false,
      OTAAckFormatError: false,
      OTARequestFormatError: false,
      remoteConfigFormatError: false,
      remoteConfigAckFormatError: false,
      notSameIdError: false,
    };

    let IdInTopics = {remote:{IMEIId:false,name:false},
      OTA:{IMEIId:false,name:false},notSame:false}
    
    

    if (otaUpgradableStatus) {
      errors.OTATopicError = otaRequestTopic.trim() === "";
      errors.OTAUpgradableTopicError = otaAckTopic.trim() === "";
      //errors.dataFormatError = dataFormat.trim() === "";
      let otaRequestFormatValidity;
      let otaAckTopicValidity ;
      if (!errors.OTATopicError){
       otaRequestFormatValidity = checkTopicValidity(otaRequestTopic, 3);
      errors.OTARequestFormatError = !otaRequestFormatValidity.valid;
      }
      if(!errors.OTAUpgradableTopicError){
       otaAckTopicValidity = checkOTAAckTopicValidity(otaAckTopic);
      errors.OTAAckFormatError = !otaAckTopicValidity.valid;
      }
      if(!errors.OTATopicError&&!errors.OTAUpgradableTopicError)
      if (otaAckTopicValidity.name && otaRequestFormatValidity.name ){IdInTopics.OTA.name=true}
      else if (otaAckTopicValidity.IMEIId && otaRequestFormatValidity.IMEIId ){IdInTopics.OTA.IMEIId=true;
    }else{IdInTopics.notSame=true;  }
  }

    if (RemoteConfigStatus) {
      errors.RemoteConfigTopicError = remoteConfigTopic.trim() === "";
      errors.RemoteConfigAckTopicError = remoteConfigAckTopic.trim() === "";
      let remoteConfigTopicValidity;
      let remoteConfigAckTopicValidity;
      if(!errors.RemoteConfigTopicError){
       remoteConfigTopicValidity = checkTopicValidity(remoteConfigTopic, 3);
      errors.remoteConfigFormatError = !remoteConfigTopicValidity.valid;
      }
      if(!errors.RemoteConfigAckTopicError){
       remoteConfigAckTopicValidity = checkTopicValidity(remoteConfigAckTopic, null);
      errors.remoteConfigAckFormatError = !remoteConfigAckTopicValidity.valid;
    }
      if (!IdInTopics.notSame&&!errors.RemoteConfigAckTopicError&&!errors.RemoteConfigAckTopicError){
      if (remoteConfigTopicValidity.IMEIId && remoteConfigAckTopicValidity.IMEIId&&((otaUpgradableStatus&&IdInTopics.OTA.IMEIId)||!otaUpgradableStatus)){IdInTopics.notSame=false}
      else if(remoteConfigTopicValidity.name && remoteConfigAckTopicValidity.name&&((otaUpgradableStatus&&IdInTopics.OTA.name)||!otaUpgradableStatus)){IdInTopics.notSame=false}
      else{IdInTopics.notSame=true;}
      }
    }
 


    

    errors.notSameIdError = IdInTopics.notSame;

    // Set all error states at once
    setNameError(errors.nameError);
    setIdError(errors.idError);
    setProductTypeError(errors.productTypeError);
    setIntervalError(errors.intervalError);
    setBatchError(errors.batchError);
    setOTATopicError(errors.OTATopicError);
    setOTAUpgradbleTopicError(errors.OTAUpgradableTopicError);
    //setDataFormatError(errors.dataFormatError);
    setRemoteConfigTopicError(errors.RemoteConfigTopicError);
    setRemoteConfigAckTopicError(errors.RemoteConfigAckTopicError);
    setOTAAckFormatError({error:errors.OTAAckFormatError,errorMsg:"Topic  is not in the format  ../--/--/{deviceID or name}"});
    setOTARequestFormatError({error:errors.OTARequestFormatError,errorMsg:"Topic  is not in the format ../--/--/{deviceID or name}/.../#"});
    setRemoteConfigFormatError({error:errors.remoteConfigFormatError,errorMsg:"Topic  is not in the format ../--/--/{deviceID or name}/.../#"});
    setnotSameIdError({error:errors.notSameIdError,errorMsg:"Included unique identifier should be same in all topics."});
    setRemoteConfigAckFormatError({error:errors.remoteConfigAckFormatError,errorMsg:"Topic is not in the format../--/--/{deviceID or name}/.../# "});

    // Check if there are any errors in the validation object
    const hasErrors = Object.values(errors).some((error) => error);

    // Return false if there are any errors, otherwise continue
    if (hasErrors) return false;

    // Continue API calls if validations pass
    let productTypeValid = null;
    if (productTypeId) {
      productTypeValid = await getProductType(productTypeId);
    }

    let validCredentials;
    if (selectedProtocol === 'HTTP') {
      validCredentials = await getHttpClient(clientId);
    } else if (selectedProtocol === 'MQTT') {
      validCredentials = await getMqttClient(clientId);
    } else {
      validCredentials = false;
    }

    return productTypeValid && validCredentials;
};


// Function to handle saving device data by calling the backend API
  const saveDeviceData = () => {
    setLoading(true);
    console.log("formData",formData)
    axios({
      method: 'POST',
      url: `${environment.host}/core/device`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
      },
      data: formData,
    })
      .then(response => {
        const data = response.data;
        if (data.message === 'Success') {
          const deviceId = [data.content.id]
          connectDeviceWithCodec(codecId, deviceId)
          setIsToasterOpen(true)
          setToasterMessage('Device data saved successfully.')
          setReqSuccess(true)
          setTimeout(() => {
            setIsToasterOpen(false);
          }, 4000);
        }
        setLoading(false);
        setStep(step + 1);
        
      })
      .catch(error => {
        setLoading(false);
        setIsToasterOpen(true)
        setToasterMessage(error.response.data.message);
        setReqSuccess(false)
        setTimeout(() => {
          setIsToasterOpen(false);
        }, 4000);
        return;
      });
  };

  //Connect Device with Codec
  const connectDeviceWithCodec = (id, device) => {
    try {
      if(id){
        axios({
          url: `${environment.host}/core/codec/${id}/device`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
          },
          data: device
        })
          .then(res => {
            if (res.status == 200) {
              console.log(res.data.content)
            }
  
          })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getProductType = async (productTypeId) => {
    try {
      const accessToken = localStorage.getItem('ACCESS_TOKEN');
      await axios({
        method: 'GET',
        url: `${environment.host}/core/product/productType/${productTypeId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return true;
  
    } catch (error) {
      console.error('Error fetching product type:', error.response.data.message);
      setReqSuccess(false);
      setToasterMessage(error.response.data.message);
      setIsToasterOpen(true);
      setTimeout(() => {
        setIsToasterOpen(false);
        setTimeout(() => {
          setLoading(true);
          window.location.reload();
        }, 100);
      }, 2000);
      return false;
    }
  };

  // Function for fetching credentials
  const fetchCredentials = async (url, clientID) => {
    try {
      await axios({
        method: 'GET',
        url: `${url}/${clientID}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN'),
        },
      });
      return true;
  
    } catch (error) {
          console.error('Error fetching client:', error.response.data.message);
          setReqSuccess(false);
          setIsToasterOpen(true);
          setToasterMessage(error.response.data.message);
          setTimeout(() => {
            setIsToasterOpen(false);
          }, 2000);
      return false; 
    }
  };

  // GET HTTP client credentials
  const getHttpClient = async (clientID) => {
    return await fetchCredentials(
      `${environment.host}/core/http-acl`, 
      clientID
    );
  };

  // GET MQTT client credentials
  const getMqttClient = async (clientID) => {
    return await fetchCredentials(
      `${environment.host}/core/vernemq-acl`,
      clientID
    );
  };

  const increaseStep = async () => {
    if (step === 0) {
      const isValid = await validateFormData();
      if (!isValid || addCredentialsSuccess === false) {
        setCredentialsError(true);
        return;
      } else {
        saveDeviceData();
      }
    } else if (step == 1) {
      setStep(step + 1);
    }
  };
  

  const decreaseStep = () => {
    if (step === 0) {
      resetFormFields();
      // window.location.href = "/";
      history.push(`/`)
    } else {
      setStep(step - 1);
      const updatedSteps = steps.map((s, i) => ({
        ...s,
        activate: i <= step - 1,
      }));
      setSteps(updatedSteps);
    }
  };

  function renderStepsComponent() {
    if (step === 1) {
      return <Step2DeviceData deviceId={deviceId} deviceName={deviceName} productType={productType}
                              interval={interval}/>;
    } else if (step === 2) {
      return <Step3ApiCall deviceId={deviceId}/>;
    } else {
      return <Step1ConnectDevice
        formData={formData}
        setFormData={setFormData}
        setDeviceName={setDeviceName}
        setDeviceId={setDeviceId}
        setProductType={setProductType}
        setProductTypeId={setProductTypeId}
        setInterval={setInterval}
        nameError={nameError}
        idError={idError}
        productTypeError={productTypeError}
        intervalError={intervalError}
        setNameError={setNameError}
        setIdError={setIdError}
        batchError={batchError}
        setBatchError={setBatchError}
        selectedBatch={selectedBatch}
        setSelectedBatch={setSelectedBatch}
        setProductTypeError={setProductTypeError}
        setIntervalError={setIntervalError}
        setAddCredentialsSuccess={setAddCredentialsSuccess}
        credentialsError={credentialsError}
        setCredentialsError={setCredentialsError}
        setClientId={setClientId}
        setSelectedProtocol={setSelectedProtocol}
        setCodecId={setCodecId}
        setOTATopic={setOTATopic}
        setOTATopicError={setOTATopicError}
        OTATopicError={OTATopicError}
        setOTAUpgradableTopic={setOTAUpgradableTopic}
        setOTAUpgradbleTopicError={setOTAUpgradbleTopicError}
        OTAUpgradbleTopicError={OTAUpgradbleTopicError}
        setOtaUpgradableStatus={setOtaUpgradableStatus}
        setRemoteConfigTopic={setRemoteConfigTopic}
        setRemoteConfigTopicError={setRemoteConfigTopicError}
        RemoteConfigTopicError={RemoteConfigTopicError}
        setRemoteConfigAckTopic={setRemoteConfigAckTopic}
        setRemoteConfigAckTopicError={setRemoteConfigAckTopicError}
        RemoteConfigAckTopicError={RemoteConfigAckTopicError}
        setRemoteConfigStatus={setRemoteConfigStatus}
        remoteConfigFormatError={remoteConfigFormatError}
        remoteConfigAckFormatError={remoteConfigAckFormatError}
        OTAAckFormatError={OTAAckFormatError}
        OTARequestFormatError={OTARequestFormatError}
        notSameIdError={notSameIdError}
  
        
      />;
    }
  }

  const resetFormFields = () => {
    setFormData({
      protocol: "MQTT",
      interval: "",
    });
    setDeviceId("");
    setDeviceName("");
    setProductType("");
    setCodecId("") 
    setSelectedBatch("");
  };

  function onClickDone() {
    timeoutRef.current = setTimeout(() => {
      setStep(0);
      setSteps(STEPS);
      setDeviceId("");
      setDeviceName("");
      setProductType("");
      setCodecId("")
    }, 2000);
    localStorage.setItem('connectDeviceCompleted', 'true');
    history.push(step == 2 ? `/` : `ConnectDevice`)
    // window.location.href = "/";
  }

  return (
    <div className={`testing`}>
      <div className="page-heading">
        Step 02
      </div>

      <Typography
        variant="subtitle1"
        style={{
          textAlign: "center",
          marginTop: "50px",
          fontSize: "16px",
          fontFamily: "Poppins, sans-serif",
          fontWeight: "bold",
        }}
      >
        Connect Test Devices
      </Typography>

      <Grid container
            style={{
              textAlign: "center",
              marginTop: "60px",
              paddingRight: "80px"
            }}>
        <Grid item xs={1} md={4}></Grid>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Grid item xs={2} md={1}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                <Circle number={index + 1} active={step.activate}completed={steps.findIndex(s => s.activate) > index}/>
                </Grid>
                <Grid item>
                  <Typography className={classes.circleText}
                    style={{
                      color: step.activate ? primaryBlue : primaryGray,
                    }}
                  >
                    {step.text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {index !== steps.length - 1 && (
              <Grid item xs={2} md={1}>
                <hr
                  style={{
                    marginTop: "32px",
                    opacity: steps[index + 1].activate ? "1" : "0.12",
                    borderColor: steps[index + 1].activate ? primaryBlue : 'rgba(112, 112, 112, .12)', 
                  }}
                  color={
                    steps[index + 1].activate ? primaryBlue : "rgba(112, 112, 112, .12)"
                  }
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
        <Grid item xs={1} md={3}></Grid>
        <Grid item xs={1} md={3}></Grid>
      </Grid>
      <Grid container className={classes.customContainer}>
      <Grid item className={classes.contentContainer}>
        {renderStepsComponent()}
      </Grid>

      <Grid item xs={12} md={12} className={classes.root}  style={{width:'100%'}}>
      <Grid container  justifyContent="flex-end" className="testing">
          {
            step !== 3 && <Button
              onClick={decreaseStep}
              variant="contained"
              className={classes.backButton}
              style={{
              boxShadow:'none',
              marginRight: '10px'
              }}
            >
              {step === 1 || step === 2 ? "Back" : "Cancel"}
            </Button>
          }
          <Button
            onClick={step === 2 ? onClickDone : increaseStep}
            variant="contained"
            color="primary"
            className={classes.saveButton} 
            disabled={loading}
            style={{
              boxShadow: 'none',
              height:"40px",
            }}
          >
            {step === 0 || step === 1 ? "Next" : loading ? (
              <CircularProgress size={24} color="inherit"/>
            ) : (
              "Done"
            )}
          </Button>
          </Grid>
        </Grid>
      </Grid>
      <ToasterComponent toaster={isToasterOpen} message={toasterMessage} reqSuccess={reqSuccess}/>
    </div>
  );
};

const Circle = (props) => {
  const paddedNumber = (props.number).toString().padStart(2, '0');
  return (
    <div>
      <div
        style={{
          borderRadius: "50%",
          width: "65px",
          height: "65px",
          backgroundColor: props.completed ? primaryBlue : white,
          border: props.active ? "2px solid #2A7CED" : (props.completed ? `2px solid ${primaryBlue}` : "0.25px solid #8F8F8F"),
          textAlign: "center",
          paddingTop: "10px",
          boxShadow: "0px 4px 8px #0000001F",
        }}
      >
        <h5 style={{
          paddingTop: "10px",
          fontSize: "16px",
          color: props.active ? primaryBlue : (props.completed ? white : primaryGray)
        }}>
          {paddedNumber}
        </h5>
      </div>
    </div>
  );
};

