import * as React from "react";
import { Button, createTheme, Grid, ThemeProvider, Typography } from "@material-ui/core";
import useStyles from "../../assets/styles";
import { useState, useEffect } from "react";
import {useHistory} from 'react-router-dom';
import { lightGray, primaryBlue, primaryGray } from "src/components/colors";
import { Step1 } from "./step1";
import { Step2 } from "./step2/step2";
import { Step3 } from "./step3";
import { SelectAModelComponent } from "./step2/SelectAModelComponent";
import { fetchBatchNumbers, rootCauseConfiguration } from "../components/apiHelper";
import { ToasterComponent } from "src/app/maintenance/test-automation/components/Toaster";

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

const STEPS = [
  {
    text: "Define Issue Type",
    activate: true,
  },
  {
    text: "Define Model",
    activate: false,
  },
  {
    text: "Results",
    activate: false,
  },
];

export function RootCauseAnalysisComponent() {
  const classes = useStyles();
  const history = useHistory();
  const [steps, setSteps] = useState(STEPS);
  const [step, setStep] = useState(0);
  const [errors,setErrors] = useState({})
  const [isClickSelectModel,setIsClickSelectModel] = useState(false)
  const [batchNumbers,setBatchNumbers] = useState([])
  const [formValue,setFormValue] = useState({batchNumber:null,analysisName:null,model:null,modelUrl:null})
  const [rootCauseResults,setRootCauseResults] = useState([])

  //Toaster
  const [isToasterOpen, setIsToasterOpen] = useState(false)
  const [toasterMessage, setToasterMessage] = useState("")
  const [reqSuccess, setReqSuccess] = useState(false)

  useEffect(() => {
    updateStepActivation(step);
  }, [step]);

  function updateStepActivation(currentStep) {
    const updatedSteps = steps.map((step, index) => ({
      ...step,
      activate: index === currentStep,
    }));
    setSteps(updatedSteps);
  }

  function renderStepsComponent() {
    if (step === 1) {
      return <Step2 
              setSelectModel={setIsClickSelectModel} 
              selectModel={isClickSelectModel}
              handleSelectModel={handleSelectModel}
              setFormValue = {setFormValue}
              errors={errors} 
              setErrors = {setErrors}
              />;
    } else if (step === 2) {
      return <Step3 result={rootCauseResults}/>;
    }
    else {
      return <Step1 
              errors={errors} 
              batchNumbers = {batchNumbers}
              setFormValue = {setFormValue}
              setErrors = {setErrors}
              formValue = {formValue}
              />;
    }
  }

  useEffect(()=>{
    const getBatchNumbers = async () => {
        try {
          const batchNumbers = await fetchBatchNumbers();
          setBatchNumbers(batchNumbers);
        } catch (err) {
          console.error('Error fetching batch numbers:', err);
        }
    };
    if(step == 0){
      getBatchNumbers();
    }
  },[step])

  const rootCauseConfigure = async() =>{
    try{
      const result = await rootCauseConfiguration(formValue);
      setRootCauseResults(result.rootCauseResults)
      setIsToasterOpen(true);
      setReqSuccess(true);
      setToasterMessage("Success");
      setTimeout(() => {
        setIsToasterOpen(false);
      }, 5000);
    }catch(error){
      console.log(error.message)
      setIsToasterOpen(true);
      setReqSuccess(false);
      setToasterMessage(error.message);
      setTimeout(() => {
        setIsToasterOpen(false);
      }, 5000);
    }
  }

  function increaseStep() {
    let selectModelClick = isClickSelectModel
    const validationError = validationForm()
    setErrors(validationError)
    if (Object.keys(validationError).length > 0) {
      return
    }
    if(selectModelClick){
      setIsClickSelectModel(false)
    }
    if(step == 1 && selectModelClick){
      rootCauseConfigure()
      resetFormFields()
    }
    
    setStep(prevStep => 
      Math.min(prevStep + 1, STEPS.length - 1)
    );
  }

  const resetFormFields = () =>{
    setFormValue(prevValue =>({
      ...prevValue,
      model : null
    }))
    setErrors(prevError=>({
      ...prevError,
      batchNumbers : null,
      analysisName : null,
      model : null
    }))
  }


  function decreaseStep() {
    let selectModelClick = isClickSelectModel
    if (step === 0) {
      // resetFormFields();
      history.push(`/TestAutomation`)
    }else if(selectModelClick){
      setIsClickSelectModel(false)
    } else {
      setStep(prevStep => 
        Math.max(prevStep - 1, 0)
      );
    }
  }

  function handleSelectModel () {
    console.log(step,isClickSelectModel)
    setIsClickSelectModel(true)
  }

  const validationForm = () => {
    let error: any = {}
    if(step == 0){
      if (formValue.batchNumber == null) {
        error.batchNumber = "Please select a batch number"
      }
  
      if (formValue.analysisName == null) {
        error.analysisName = "Please enter analysis name"
      }
    }else if(step == 1){
      if (formValue.model == null) {
        error.model = "Please select a model"
      }
    }
    


    return error

  }

  function handleSubmit() {
    let form = formValue;
    // Submit logic here
    if (step === 2) {
      // resetFormFields();
      history.push(`/TestAutomation`)
    }else if(step == 0) {
      if(!form.batchNumber){
        setErrors(prevError =>({
          ...prevError,
          'batchNumber':'Please select a batch number'
        }))
      }
      if(!form.analysisName){
        setErrors(prevError =>({
          ...prevError,
          'analysisName':'Please enter analysis name'
        }))
      }
    }else if(step == 1 && isClickSelectModel){
      if(!form.model){
        setErrors(prevError =>({
          ...prevError,
          'model':'Please select a model'
      }))
    }
      console.log(form)
    }
    console.log(step)
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <div className="page-heading" style={{ marginBottom: "50px" }}>
          Step 04
        </div>

        <Grid container alignItems="center" >
          <Grid item xs={12} md={12} style={{ textAlign: "center", paddingBottom: "40px" }}>
            <h4>Route Cause Analysis</h4>
          </Grid>
        </Grid>

        <Grid container style={{textAlign: "center",paddingRight: "80px"}}>
        <Grid item xs={1} md={4}></Grid>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <Grid item xs={2} md={1}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <Circle number={index + 1} active={step.activate}/>
                </Grid>
                <Grid item>
                  <Typography className={classes.circleText}
                    style={{
                      color: step.activate ? primaryBlue : primaryGray,
                    }}
                  >
                    {step.text}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {index !== steps.length - 1 && (
              <Grid item xs={2} md={1}>
                <hr
                  style={{
                    marginTop: "32px",
                    opacity: steps[index + 1].activate ? "1" : "0.12",
                  }}
                  color={
                    steps[index + 1].activate ? primaryBlue : "rgba(112, 112, 112, .12)"
                  }
                />
              </Grid>
            )}
          </React.Fragment>
        ))}
        <Grid item xs={1} md={3}></Grid>
      </Grid>

      <Grid container className={classes.customContainer}>
      <Grid item className={classes.contentContainer}>
            {renderStepsComponent()}
          </Grid>
          <Grid item xs={12} md={12} className={classes.root}  style={{width:'100%'}}>
            <Grid container spacing={2} justifyContent="flex-end" className="testing">
                {step !== 3 && (
                    <Button
                      onClick={decreaseStep}
                      variant="contained"
                      className={classes.backButton}
                      style={{margin:'10px'}}
                    >
                      {step === 1 || step === 2 ? "Back" : "Cancel"}
                    </Button>
                )}
                { (step !== 1 && step !== 2) && 
                    <Button
                      onClick={increaseStep}
                      variant="contained"
                      className={`${classes.getStarted} ${classes.button}`}
                      style={{margin:'10px'}}
                    >
                      {step === 0 ? "Next" : "Done"}
                    </Button>
                }
                {isClickSelectModel &&
                  <Button
                  onClick={increaseStep}
                  variant="contained"
                  className={`${classes.getStarted} ${classes.button}`}
                  style={{margin:'10px'}}
                >
                  Run
                </Button>
                }
                {
                  step ===2 && 
                  <Button
                  onClick={()=>history.push("/TestAutomation")}
                  variant="contained"
                  className={`${classes.getStarted} ${classes.button}`}
                  style={{margin:'10px'}}
                >
                  Done
                </Button>
                }  
              </Grid>
          </Grid>
        </Grid>
      </div>
      <ToasterComponent
                  toaster={isToasterOpen}
                  message={toasterMessage}
                  reqSuccess={reqSuccess}
                />
    </ThemeProvider>
  );
}

const Circle = (props) => {
  const paddedNumber = (props.number).toString().padStart(2, '0');
  return (
    <div>
      <div
        style={{
          borderRadius: "50%",
          width: "65px",
          height: "65px",
          backgroundColor: "white",
          border: props.active ? "2px solid #2A7CED" : "0.25px solid #8F8F8F",
          textAlign: "center",
          paddingTop: "10px",
          boxShadow: "0px 4px 8px #0000001F",
        }}
      >
        <h5 style={{
          paddingTop: "10px",
          fontSize: "16px",
          color: props.active ? primaryBlue : primaryGray
        }}>
          {paddedNumber}
        </h5>
      </div>
    </div>
  );
};