import {
  Button,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  ThemeProvider,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import * as React from "react";
import {useEffect, useState} from "react";
import { useHistory } from 'react-router-dom';
import {environment} from "src/environments/environment";
import {primaryBlue, secondaryTextColor, white,} from "../../components/colors";
import {IconDropDown} from "../../components/sub-components/IconDropDown";
import {SearchBar} from "../../components/sub-components/SearchBar";
import {ContainedStar, EditIconLarge, Info, MenuDots, OutlinedStar, Refresh, ViewIcon} from "../../components/Icons";
import {EditProduct} from "./edit-product/EditProduct";
import {createTheme} from '@material-ui/core/styles';
import axios from "axios";
import useStyles from "src/app/maintenance/assets/styles";
import { Pagination } from "@material-ui/lab";

export function ProductTypComponent(props) {
  const classes = localStyles();
  const style =  useStyles();
  const history = useHistory();

  const [userId, setUserId] = useState("");
  const [filterFavouriteDevices, setFilterFavouriteDevices] = useState(false);
  const [favoriteDevices, setFavouriteDevices] = useState([]);
  const [type, setType] = useState("");
  const [edit, setEdit] = useState(false);
  const [configurations, setConfigurations] = useState(true);
  const [editProductName, setEditProductName] = useState("");
  const [editProductId, setEditProductId] = useState("");
  const [search, setSearch] = useState("");

  const [products, setProducts] = useState([]);
  const [constProducts, setConstProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allVersions, setAllVersions] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const theme = createTheme({
    typography: {
      fontFamily: "'Poppins', sans-serif",
    },
  });

  const handleConfigurationsClick = () => {
    history.push('/product-types/configurations');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openEdit = (product, id, type) => {
    setType(type);
    setEditProductName(product);
    setEditProductId(id);
    setEdit(true);
  };
  const closeEdit = () => {
    setEdit(false);
  };


  //filters
  const allProductFilter = (e) => {
    const productFilter = constProducts.filter(
      (obj) => obj.productName === e.target.value
    );
    setProducts(productFilter);
    setFilterFavouriteDevices(false);
  };
  const allCategoryFilter = (e) => {
    const categoryFilter = constProducts.filter(
      (obj) => obj.deviceCategory === e.target.value
    );
    setProducts(categoryFilter);
    setFilterFavouriteDevices(false);
  };
  const allVersionFilter = (e) => {
    let versionsFilter = [];
    constProducts.map((obj) => {
      obj.versions.map((v) => {
        if (v.versionNum === e.target.value) {
          versionsFilter.push(obj);
        }
      });
    });
    setProducts(versionsFilter);
    setFilterFavouriteDevices(false);
  };

  const getAllProducts = () => {
    axios.get(`${environment.host}/core/products`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        setConstProducts(response.data.content);
        console.log(response.data.content, "+++++++++++++++++++++++++++");
        setProducts(response.data.content);
      })
      .catch((error) => {
        setConstProducts([]);
        setProducts([]);
      });
  };
  const getFavourites = (id) => {
    axios.get(`${environment.host}/core/product/${id}/favourite`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        setFavouriteDevices([]);
      })
      .catch((err) => {
        setFavouriteDevices([]);
      });
  };

  const filterFavourite = () => {
    setFilterFavouriteDevices(!filterFavouriteDevices);
    if (filterFavouriteDevices === false) {
      setProducts(products.filter((obj) => favoriteDevices.includes(obj.id)));
    }
    if (filterFavouriteDevices === true) {
      getAllProducts();
    }
  };

  const addOrRemoveFavourite = (favourite, id) => {
    const updatedFavoriteDevices = [...favoriteDevices];

    if (favourite === false) {
      axios.post(`${environment.host}/core/product/${userId}/favourite`, id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      })
        .then((response) => {
          updatedFavoriteDevices.push(id);
          setFavouriteDevices(updatedFavoriteDevices);
        })
        .catch((error) => {
          getFavourites(userId);
        });
    }
    if (favourite === true) {
      axios.delete(`${environment.host}/core/product/${userId}/favourite`,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
        data : [id],
      })
        .then((response) => {
          //getFavourites(userId);
          const index = updatedFavoriteDevices.indexOf(id);
          if (index !== -1) {
            updatedFavoriteDevices.splice(index, 1);
            setFavouriteDevices(updatedFavoriteDevices);
          }
        })
        .catch((error) => {
          getFavourites(userId);
        });
    }
  };

  useEffect(() => {
    const uId = localStorage.getItem("USER_ID");
    setUserId(uId);
    getAllProducts();

    axios.get(`${environment.host}/core/product/configDetails`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
      .then((response) => {
        let allProducts = [];
        let allCategories = [];
        response.data.content.productTypes[0].map((p) => {
          allProducts.push(p.productType);
          p.deviceCategory.map((c) => {
            allCategories.push(c);
          });
        });
        setAllProducts(allProducts);
        setAllCategories(allCategories);
        setAllVersions(response.data.content["available versions"][0]);
      })
      .catch((err) => {
        setAllProducts([]);
        setAllCategories([]);
        setAllVersions([]);
      });
    getFavourites(uId);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <div className={`testing`}>
        <div className="page-heading">
          Product Types
        </div>
        {edit === false ? (
          <Grid container className={style.container}>
            <Grid container alignItems="center">
              <Grid item xs={6} md={2} style={{ textAlign: "left" }}>
                <SearchBar
                  placeholder="Search Product Name"
                  onChange={(e) => setSearch(e.target.value)}/></Grid>
                  <Grid item md={8}></Grid>
                            <Grid
                              item
                              xs={6}
                              md={2}
                              style={{ textAlign: "right",}}
                            >
                <Button
                  variant="contained"
                  onClick={handleConfigurationsClick}
                  className={classes.configuration}
                  style={{textTransform: "none"}}
                >
                  Configurations
                </Button>
                </Grid>
            </Grid>

                <Grid
                  container
                  style={{
                    backgroundColor: "white",
                  }}
                >
                  <Grid
                    item
                    xs={5}
                    md={5}
                    style={{
                      textAlign: "left",
                      padding: "16px",
                    }}
                  >
                    <Checkbox disabled/>
                    <IconButton style={{padding: "0px"}} onClick={filterFavourite}>
                      {filterFavouriteDevices === false ? (
                        <OutlinedStar color="#0000008A"/>
                      ) : (
                        <ContainedStar/>
                      )}
                    </IconButton>
                    <IconButton
                      style={{padding: "0px"}}
                      onClick={() => {
                        setFavouriteDevices([]);
                        setProducts([]);
                        getAllProducts();
                      }}
                    >
                      <Refresh/>
                    </IconButton>
                    <IconButton style={{padding: "0px"}}>
                      <MenuDots/>
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                >
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              textAlign: "center",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor: "white",
                            }}
                          ></TableCell>
                          <TableCell
                            style={{
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor: "white",
                            }}
                          >
                            <Typography className={style.tablehead} 
                              display="inline"
                            >
                              Product Name
                            </Typography>
                            <IconDropDown
                              onChange={(e) => allProductFilter(e)}
                              data={allProducts}
                            />
                          </TableCell>
                          {/* <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor: "white",
                            }}
                          >
                            <Typography
                              display="inline"
                              style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                              }}
                            >
                              Device/Node Category
                            </Typography>
                            <IconDropDown
                              onChange={(e) => allCategoryFilter(e)}
                              data={allCategories}
                            />
                          </TableCell> */}
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor: "white",
                            }}
                          >
                            <Typography className={style.tablehead} 
                              display="inline"
                            >
                              Available Versions
                            </Typography>
                            <IconDropDown
                              onChange={(e) => allVersionFilter(e)}
                              data={allVersions}
                            />
                            <NewTooltip
                              arrow
                              title={
                                <React.Fragment>
                                  <Grid container>
                                    <Grid
                                      item
                                      xs={3}
                                      md={3}
                                    >
                                      <Chip
                                        label=""
                                        className={classes.redIndicator}
                                      ></Chip>
                                    </Grid>
                                    <Grid item xs={9} md={9}>
                                      <Typography
                                        display="inline"
                                        style={{fontSize: "12px", color: "#63738C"}}
                                      >
                                        Indicates that version is available but not
                                        approved, so you can edit any modifications
                                      </Typography>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      md={3}
                                      style={{
                                        textAlign: "center",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      <Chip
                                        label=""
                                        className={classes.greenIndicator}
                                      ></Chip>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={9}
                                      md={9}
                                      style={{paddingTop: "10px"}}
                                    >
                                      <Typography
                                        display="inline"
                                        style={{fontSize: "12px", color: "#63738C"}}
                                      >
                                        Indicates that version is available &
                                        released, so you can't edit any modifications
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              }
                            >
                              <IconButton style={{padding: "0px"}}>
                                <Info/>
                              </IconButton>
                            </NewTooltip>
                          </TableCell>
                          <TableCell
                            style={{
                              textAlign: "left",
                              borderBottomColor: "white",
                              borderTopColor: "white",
                              backgroundColor: "white",
                            }}
                          >
                            <Typography className={style.tablehead}>Actions</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {products !== undefined &&
                          products
                            .filter((obj) => {
                              if (search === "") {
                                return obj;
                              } else if (
                                obj.productName
                                  .toLowerCase()
                                  .includes(search.trim().toLowerCase())
                              ) {
                                return obj;
                              }
                            })
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((product, i) => (
                              <TableRow key={i}>
                                <TableCell
                                  style={{
                                    textAlign: "left",
                                    borderBottomColor: "white",
                                    borderTopColor: "white",
                                    backgroundColor:
                                      i % 2 === 0 || i / 2 === 0
                                        ? "#F9F9F9"
                                        : "white",
                                  }}
                                >
                                  <Checkbox disabled/>
                                  <IconButton
                                    style={{padding: "0px"}}
                                    onClick={() =>
                                      addOrRemoveFavourite(
                                        favoriteDevices !== undefined &&
                                        favoriteDevices.includes(product.id),
                                        product.id
                                      )
                                    }
                                  >
                                    {favoriteDevices !== undefined &&
                                    favoriteDevices.includes(product.id) ? (
                                      <ContainedStar/>
                                    ) : (
                                      <OutlinedStar color="#00000029"/>
                                    )}
                                  </IconButton>
                                  </TableCell>

                                  {/* Separate TableCell for Product Name */}
                                  <TableCell
                                    style={{
                                      textAlign: "left",
                                      borderBottomColor: "white",
                                      borderTopColor: "white",
                                      backgroundColor: i % 2 === 0 || i / 2 === 0 ? "#F9F9F9" : "white",
                                    }}
                                  >
                                  <Typography
                                    display="inline"
                                    className={classes.tableText}
                                  >
                                    {product.productName}
                                  </Typography>
                                </TableCell>
                                {/* <TableCell
                                  style={{
                                    textAlign: "left",
                                    borderBottomColor: "white",
                                    borderTopColor: "white",
                                    backgroundColor:
                                      i % 2 === 0 || i / 2 === 0
                                        ? "#F9F9F9"
                                        : "white",
                                  }}
                                >
                                  <Typography className={classes.tableText}>
                                    {product.deviceCategory}
                                  </Typography>
                                </TableCell> */}
                                <TableCell
                                  style={{
                                    textAlign: "left",
                                    width: "40%",
                                    borderBottomColor: "white",
                                    borderTopColor: "white",
                                    backgroundColor:
                                      i % 2 === 0 || i / 2 === 0
                                        ? "#F9F9F9"
                                        : "white",
                                  }}
                                >
                                  {product.otaUpgradable?product.versions.map((version, j) => (
                                    <Chip
                                      key={j}
                                      className={
                                        version.status === "NOT_APPROVED"
                                          ? classes.redChip
                                          : classes.greenChip
                                      }
                                      label={version.versionNum}
                                    />
                                  )):
                                    <Typography display="inline"
                                                    className={classes.tableText}
                                                    style={{
                                                      color: "grey"
                                                    }}>
                                                    N/A
                                    </Typography>}
                                </TableCell>
                                <TableCell
                                  style={{
                                    textAlign: "left",
                                    borderBottomColor: "white",
                                    borderTopColor: "white",
                                    backgroundColor:
                                      i % 2 === 0 || i / 2 === 0
                                        ? "#F9F9F9"
                                        : "white",
                                  }}
                                >
                                  <IconButton
                                    style={{padding: "0px"}}
                                    onClick={() =>
                                      openEdit(
                                        product.productName,
                                        product.id,
                                        "view"
                                      )
                                    }
                                  >
                                    <ViewIcon/>
                                  </IconButton>
                                  {product.versions.filter(
                                    (ver) => ver.status === "NOT_APPROVED"
                                  ).length > 0 && (
                                    // Hide edit icon if all versions are approved.
                                    <IconButton
                                      style={{padding: "0px", paddingLeft: "10px"}}
                                      onClick={() =>
                                        openEdit(
                                          product.productName,
                                          product.id,
                                          "edit"
                                        )
                                      }
                                    >
                                      <EditIconLarge/>
                                    </IconButton>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid container justifyContent="center">
                  <Pagination className={style.pagination}
                    count={Math.ceil(products.length / rowsPerPage)}
                    page={page + 1}
                    onChange={(event, value) => setPage(value - 1)}
                  />
                </Grid>
          </Grid>
        ) : (
          <EditProduct
            onClose={() => closeEdit()}
            setEditProductName={editProductName}
            editProductId={editProductId}
            type={type}
          />
        )}
      </div>
    </ThemeProvider>
  );
}

const localStyles = makeStyles((theme: Theme) => ({
  filter: {
    backgroundColor: white,
    color: secondaryTextColor,
    boxShadow: "none",
  },
  configuration: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
  },
  greenChip: {
    backgroundColor: "#03C69F1F",
    color: "#03C69F",
    height: "20px",
    marginRight: "5px",
  },
  redChip: {
    backgroundColor: "#FC41411F",
    color: "#FC4141",
    height: "20px",
    marginRight: "5px",
  },
  greenIndicator: {
    backgroundColor: "#03C69F1F",
    height: "20px",
    width: "30px",
  },
  redIndicator: {
    backgroundColor: "#FC41411F",
    height: "20px",
    width: "30px",
  },
}));

const NewTooltip = withStyles({
  tooltip: {
    color: "black",
    backgroundColor: "white",
    boxShadow: "0px 3px 6px #00000029",
    padding: "10px",
  },
})(Tooltip);



