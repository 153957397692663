import { red } from '@material-ui/core/colors';
import { makeStyles, alpha } from '@material-ui/core/styles';
import { BorderColor } from '@material-ui/icons';
import { lightGray, primaryBlue, primaryGray, secondaryTextColor, textBarColor, white } from "src/components/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "50px",
    textAlign: "left",
    borderRadius: "3px",
  },
  boxContainer: {
    position: "relative",
    padding: "0px 20px 20px 0px",
    marginTop: "20px",
    background: "#FFFFFF",
    boxShadow: "0px 3px 8px #00000012",
    borderRadius: "16px",
    opacity: 1,
  },
  statusContainer: {
    display: "flex"
  },
  chipContainer: {
    marginRight: "0px",
    marginTop:"10px"
  },
  blur: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  step: {
    marginLeft: "0px"
  },
  titleContainer: {
    fontSize: "18px",
    color: "#000000",
    fontWeight: 600,
    marginLeft: "30px",
    marginRight: "80px",
  },
  dataStreamTitleContainer: {
    fontSize: "25px",
    color: "#5E5C5C",
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
  },
  description: {
    fontWeight: 200,
    fontSize: "15px",
    margin: "10px 5px 30px 30px",
  },
  dataStreamDescription: {
    fontWeight: 200,
    fontSize: "15px",
    paddingTop: "10px",
    margin: "0px 10px 20px 10px",
    display: "flex",
    justifyContent: "center"
  },
  getStarted: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  option: {
    padding: '0px 10px',
    height: '30px',
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '13px',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    paddingRight: '10px',
  },
  dropdown: {
    width: '100%',
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    border: '1px solid #C7C4C4',
    borderRadius: '5px',
    backgroundColor: white,
    zIndex: 1,
    maxHeight: '160px',
    overflowY: 'auto',
    cursor: 'pointer',
  },
  inputBaseStyle: {
    width: '100%',
    height: "38px",
    borderRadius: "5px",
    borderColor: lightGray,
    borderStyle: "solid",
    borderWidth: "1px",
    fontSize: "13px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  formContent: {
    padding: "40px",
    textAlign: "left",
    borderRadius: "5px",
    borderColor: lightGray,
    borderStyle: "solid",
    borderWidth: "1px",
  },
  formInput: {
    width: '100%',
    height: "38px",
    borderRadius: "5px",
    border: ".75px solid rgba(63, 68, 81, .12156862745098039)",
    borderStyle: "solid",
    borderWidth: "1px",
    padding: "10px 15px",
    fontSize: "13px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    color:primaryGray,
    backgroundColor:white,
    fontFamily: 'Poppins',
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    '& .MuiOutlinedInput-input::placeholder': {
      color: '#808080', 
      opacity: 1,
    },
  },
  addNew: {
    backgroundColor: lightGray,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: lightGray,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  buttonContainer: {
    display: 'flex',
  },
  button: {
    minWidth: '120px',
    borderRadius: '4px',
    textTransform: 'none',
    width:'160px',
  },
  backButton: {
    color: primaryGray,
    width: '160px',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    boxShadow: "none",
    border: "1px solid #707070",
    "&:hover": {
      backgroundColor:"transparent",
      color: primaryGray,
      boxShadow: "none",
      border: "1px solid #707070",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
    minWidth:'120px'
  },
  refreshButton: {
    border: '1px solid #02C170',
    borderRadius: '4px',
    padding: '8px 20px 8px 20px',
    cursor: 'pointer',
    color: '#02C170',
    backgroundColor: '#FFFFFF'
  },
  submitButton: {
    borderRadius: '4px',
    padding: '8px 50px 8px 50px',
    cursor: 'pointer',
    color: '#FFFFFF',
    backgroundColor: '#2A7BEC'
  },
  radio: {
    '&.Mui-checked': {
      color: primaryBlue,
    },
    '&:hover': {
      backgroundColor: alpha(primaryBlue, 0.1),
    },
    '&.Mui-checked:hover': {
      backgroundColor: alpha(primaryBlue, 0.1),
    },
  },
  filterRadio:{
    '&.Mui-checked': {
      color: '#02C170',
    },
    '&:hover': {
      backgroundColor: alpha('#02C170', 0.1),
    },
    '&.Mui-checked:hover': {
      backgroundColor: alpha('#02C170', 0.1),
    },
  },
  filterBack:{
    color: primaryGray,
    width: '110px',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    boxShadow: "none",
    border: "1px solid #707070",
    "&:hover": {
      backgroundColor:"transparent",
      color: primaryGray,
      boxShadow: "none",
      border: "1px solid #707070",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
    minWidth:'110px'
  },
  filterButton:{
    minWidth: '110px',
    borderRadius: '4px',
    textTransform: 'none',
    width:'110px',
  },
  label: {
    alignSelf: 'flex-start',
    marginTop: '10px',
    fontSize: '14px !important', 
    fontFamily: 'Poppins, sans-serif',
  },
  typo: {
    fontSize: "14px",
    fontFamily:"poppins",
    color:"#212529"
  },
  deleteButton: {
    color: 'red',
  },
  descriptionContainer: {
    fontWeight: 200,
    fontSize: "15px",
    margin: "20px 50px 30px 30px",
  },
  errorText: {
    color: "red",
    fontSize: "12px",
  },
  selectModel: {
    padding: "10px 20px",
    gap: "10px",
    height: "42px",
    border: "0.5px solid rgba(85, 85, 85, 0.5)",
    borderRadius: "5px",
    cursor: 'pointer'
  },
  chip: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    fontSize: '12px',
    borderRadius: '16px',
    padding: '4px 8px',
    '& .MuiChip-deleteIcon': {
      color: theme.palette.text.secondary,
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  table: {
    minWidth: 650,
    border: '1px solid #ddd',
    borderCollapse: 'collapse',
  },
  contentText: {
    fontSize: '14px',
  },
  topicText: {
    fontSize: '14px',
    color: "#30335b",
    fontWeight: "bold",
  },
  deviceId: {
    fontSize: '14px',
    color: "#30335b",
  },
  input: {
    width: "100%",
    textAlign: "left",
    backgroundColor: white,
    fontSize:"14px"
  },
  cloneButtonHover: {
    backgroundColor: 'transparent',
    color: primaryBlue,
    boxShadow: 'none',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      boxShadow: 'none',
    },
  },
  subIconButtonDiv: {
    backgroundColor: "#F5F5F5",
    width: "25px",
    height: "25px",
    borderRadius: "100%",
    textAlign: "center",
  },
  iconButtonStyle: {
    backgroundColor: "ButtonShadow",
    borderRadius: "80%",
    boxShadow: "0px 3px 6px #00000029",
    padding: "3px",
  },
  addButton: {
    backgroundColor: white,
    color: primaryBlue,
    borderColor: primaryBlue,
  },
  inputDefaultValue: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  native: {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    width: '100%',
    fontSize: '16px',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: 2,
    width: "100%",
  },
  add: {
    backgroundColor: primaryBlue,
    color: "white",
    marginBottom: "10px",
    marginRight: "10px",
    width: "20%",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(primaryBlue, 0.8),
      color: white,
      boxShadow: "none",
    },
  },
  cancel: {
    backgroundColor: 'transparent',
    color: primaryGray,
    marginBottom: "10px",
    marginRight: "10px",
    width: "20%",
    boxShadow: "none",
    border: "1px solid #707070",
    "&:hover": {
      backgroundColor: "transparent",
      bord: "black",
      color: primaryGray,
      boxShadow: "none",
      border: "1px solid #707070",
    },
  },

  saveButton: {
    width: '160px',
    borderRadius: '4px',
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: alpha(primaryBlue, 0.8),
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
    minWidth:"120px"
  },
  options: {
    // width: 'calc((100% - 40px ) / 3)',
    height: '300px',
    borderRadius: '5px',
    backgroundColor: '#FFFFFF',
    minWidth: '200px',
    textAlign: 'center',
    lineHeight: '20px',
    color: '#5E5C5C',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    "&:hover": {
      backgroundColor: '#2A7BEC',
      color: white
    },
    margin: 0,
    border: '0.6px solid #DDDDDD',
    boxShadow: '0px 4px 40px 0px #00000014'

  },
  dropdownOptions: {
    padding: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    color:'#8d979f',
    '&:hover': {
      backgroundColor: '#2A7BEC',
      color: white
    }
  },
  accordion: {
    marginTop: '10px',
    padding: '0px',
    border: 'none', // Remove border
    boxShadow: 'none', // Remove box-shadow
    '& .MuiAccordionSummary-root': {
      border: 'none', // Remove border
      boxShadow: 'none', // Remove box-shadow
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px',
    },
  },
  chatBotInput: {
    '&.MuiInputBase-input': {
      padding: '15px',
    },
    '&.MuiFormControl-root': {
      backgroundColor: '#F2F4F5'
    },
    '& .MuiFilledInput-root': {
      padding: '10px', // Adjust padding as needed
      borderRadius: '25px',
      height:'50px' // Optional: Change border-radius
    },
    borderRadius: '25px',
    color:'#444444',
    fontStyle:'Poppins'
  },
  chatBotPageInput: {
    '&.MuiInputBase-input': {
      padding: '15px',
    },
    '&.MuiFormControl-root': {
      backgroundColor: '#F2F4F5'
    },
    '& .MuiFilledInput-root': {
      padding: '10px',
      borderRadius: '10px',
      height:'50px'
    },
    color:'#444444',
    fontStyle:'Poppins'
  },
  phoneIcon: {
    marginRight: '10px',
    boxShadow: '0px 4px 40px 0px #0000001A',
    padding: '8px',
    height: '41px',
    borderRadius: '20.5px',
    width: ' 41px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex'
  },
  helperText:{
    color:"red",
    marginBottom:"2px"
  },
  required: {
    color: "red",
  },
  iconStyle:{
    padding:"0px",
    marginRight: "10px",
    '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
  },
  customButton:{
    backgroundColor: primaryBlue,
    color: white,
    textTransform: "none",
    fontFamily:"poppins",
    padding:"10px 24px",
    height:"40px",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px",
    },
  },
  filter:{
    color: secondaryTextColor,
    textTransform: "none",
    border: "  1px solid #dadadaaa",
    fontSize: "14px",
    padding: "10px 15px 10px 10px",
    height: "40px",
    lineHeight: "20px",
    background:"white",
    fontFamily:"poppins",
    '&:hover': {
    backgroundColor: 'transparent',
  },
  },
  container:{
    padding:"20px"
  },
  customLabel:{
    textAlign: "left",
    marginBottom: "22px",
    paddingRight: "2%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  typographyStyle:{
    color: primaryGray,
    fontSize: "14px",
    lineHeight: "21px",
    opacity: "1",
    width: "100%", 
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  customCloseIcon:{
    display: "flex",
    justifyContent: "end",
  },
  tooltipCustom:{
    display:"flex",
    alignItems:"center",
    fontSize:"14px"
  },
  DragDropText: {
    textAlign: "center",
    fontFamily: "'Poppins', sans-serif"
  },
  dragText:{
    fontSize:"16px",
    color:"#707070",
    fontWeight:500
  },
  dragTextSmall:{
    fontSize: "15px",
    color: primaryGray
  },
  dragDrop: {
    top: "316px",
    left: "361px",
    height: "180px",
    background: "#F8F8F8 0% 0% no-repeat padding-box",
    border: "2px dashed #427AE5",
    borderRadius: "5px",
    opacity: 1,
    padding:"32px"
  },
  codecButton:{
    cursor: 'pointer',
    padding: '0px',
    textAlign: 'center',
    color: primaryGray,
    backgroundColor: white,
    borderRadius:"0px",
  },
  circleText:{
    fontSize: "12px",
    paddingTop: "15px",
    fontFamily: "Poppins, sans-serif",
  },
  protocolButton:{
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop:'10px',
    gap: '10px',
  },
  customContainer:{
    paddingTop:'20px',
    width:'60%',
    alignItems:'center',
    justifyContent:'center',
    display:'flex',
    flexDirection:'column'
  },
  contentContainer:{
    width:'100%',
    marginBottom:'15px'
  },
  greenChip: {
    backgroundColor: "#03C69F1F",
    color: "#03C69F",
    height: "20px",
  },
  yellowChip: {
    backgroundColor: "#FFB40A1F",
    color: "#FFB40A",
    height: "20px",
  },
  redChip: {
    backgroundColor: "#FC41411F",
    color: "#EE3939",
    height: "20px",
  },
  blueChip: {
    backgroundColor: "#2a7ced",
    color: "#fff",
    height: "20px",
  },
  blueChipOpaque: {
    backgroundColor: "#2a7bec1a",
    color: "#2a7bec",
    height: "20px",

  },
  tablehead:{
  fontWeight: "bold",
  fontSize: "14px",
  paddingTop: "10px",
  paddingBottom: "10px",
  fontFamily:"poppins"
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      borderRadius: '0px',
    },
    '& .Mui-selected': {
      backgroundColor: '#2199e8 !important',
      color: 'white', 
    },
  },
  form:{
    width: '100%',
    height: "38px",
    borderRadius: "5px",
    border: "none",
    borderWidth: "1px",
    padding: "0px",
    fontSize: "13px",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    color:primaryGray,
    backgroundColor:"transparent",
    fontFamily: 'Poppins',
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    '& .MuiOutlinedInput-input::placeholder': {
      color: '#808080', 
      opacity: 1,
    },
  },
  cloudItem:{
    border: '.75px solid rgba(63, 68, 81, .12)',
    width: 'auto',
    borderRadius: '5px',
    padding: '2px 6px',
    alignItems: 'center',
    margin: '0 5px 2px 0',
    height: '30px',
    display: 'flex',
  },
  cloudItem_number:{
    padding: '2px 5px',
    fontSize: '12px',
    textAlign: 'center',
    color: '#707070',
  },
  cloudItem_line:{
    border: '.75px solid rgba(63, 68, 81, .12)',
    height: '20px',
    margin: '0 5px',
  },
  cloudItem_text:{
    padding: '2px 5px',
    fontSize: '12px',
    textAlign: 'center',
    color: '#707070',
  },
  cloudItem_close:{
   cursor:'pointer',
   margin:'2px',
   display:'flex',
   alignItems:'center',
   justifyContent:'center'
  },
  popupHeading:{
    fontFamily:'poppins',
    fontSize:'16px',
    fontWeight:'bold'
  }

}));

export default useStyles;
