import { Typography } from "@material-ui/core";
import React, { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { primaryGray } from "src/components/colors";
import BrowseButton from "src/components/sub-components/BrowseButton";
import { DragDrop } from "src/components/sub-components/DragAndDrop";
import useStyles from "src/app/maintenance/assets/styles";


function DropZoneWithFileFilter({readFiles,fileName,fileExtensions}) {
    const style = useStyles(); 
    const inputRef=useRef(null);
    const handleClick = () => {
      inputRef.current.click(); 
    };
    const handleFileChange = (e) => {
      const files = e.target.files;
      readFiles(files)
    };
  
  
  
    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
      onDrop: useCallback((acceptedFiles) => {
         readFiles(acceptedFiles)
      }, []),
      multiple:false,
      noClick: true,
      accept:{'':fileExtensions}
    },
    
     );
    return (
      <section>
        <input type="file" accept={fileExtensions.join(",")} ref={inputRef} hidden={true} onChange={handleFileChange} />
        <div {...getRootProps({ isFocused, isDragAccept, isDragReject })}  className={style.dragDrop} onClick={handleClick}>
          <DragDrop/>
          <Typography style={{textAlign:'center'}}>
                    <span
                     className={style.dragText}
                    >
                      {fileName === ""
                        ? "Drag & Drop file here to upload or"
                        : fileName}
                    </span>
  
                    <BrowseButton
                      isFileSelected={fileName !== ""}
                    />
                  </Typography>
       
           
          
        </div>
      </section>
    );
  }
  export default DropZoneWithFileFilter;
  {/**
  This component is a customized version of `react-dropzone`, which filters file types
  in the file selection explorer using file extensions
  ( `react-dropzone`  only supports MIME types). 
 */}