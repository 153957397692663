import * as React from 'react';
import { createTheme, Grid, InputBase, ThemeProvider, Typography} from '@material-ui/core';
// @ts-ignore
import { ViewProductType } from '../../../product-types/configure-product/ViewProductType';
import useStyles from '../../../assets/styles';

const theme = createTheme({
  typography: {
    fontFamily: '\'Poppins\', sans-serif',
  },
});

export default function Step1ConnectDevices({
                                              formData,
                                              setFormData,
                                              connectionString,
                                              setConnectionString,
                                              connectionStringError,
                                              setConnectionStringError,
                                              apiVersion,
                                              setApiVersion,
                                              apiVersionError,
                                              setApiVersionError

                                            }){
  const classes = useStyles();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const trimmedValue = value.trim();
    if(name === "connectionString"){
      setConnectionString(trimmedValue);
      setConnectionStringError(false);
    } else if(name === "apiVersion"){
      setApiVersion(trimmedValue);
      setApiVersionError(false);
    }
    setFormData({
      ...formData,
      [name]: trimmedValue
    });
  }
  return (
    <ThemeProvider theme={theme}>
     <Grid container className={classes.root}>
     <Grid container className={classes.formContent}>
      <Grid container alignItems="center">
          <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                <Typography className={classes.typo}>
                  IoT Hub Connection String
                  <span style={{ color: 'red' }}>*</span>
                </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputBase
                    className={classes.formInput}
                    type="text"
                    placeholder="Enter IoT Hub Connection String Here"
                    name="connectionString"
                    value={connectionString}
                    onChange={handleInputChange}
                  />
                </Grid>
                </Grid>
                {connectionStringError && (
                  <Grid container justifyContent="flex-end" alignItems="center" style={{marginTop: "2px" }}>
                    <Typography className={classes.errorText}>
                      IoT Hub Connection String is Required
                    </Typography>
                  </Grid>
                )}
              <Grid container spacing={1} style={{marginTop: "20px"}}>
                <Grid item xs={12}>
                <Typography className={classes.typo}>
                  Api Version
                  <span style={{color: 'red'}}>*</span>
                </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                  <InputBase
                    className={classes.formInput}
                    type="text"
                    placeholder="Enter Api Version"
                    name="apiVersion"
                    value={apiVersion}
                    onChange={handleInputChange}
                  />
                </Grid>
                </Grid>
                {apiVersionError && (
                  <Grid container justifyContent="flex-end" alignItems="center" style={{ marginTop: "2px" }}>
                    <Typography className={classes.errorText}>
                      Api Version is Required
                    </Typography>
                  </Grid>
                )}
              </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>

  );
}
