import {uploadFile} from "react-s3";
import {environment} from "src/environments/environment";
import {Buffer} from "buffer";
import axios from "axios";

window.Buffer = window.Buffer || Buffer;

const flowChartConfig = {
  dirName: "product-types/flow-chart",
  bucketName: "senzmate-polar",
  region: "us-west-2",
  accessKeyId: "AKIA5XGNU22II44QCSGH", 
  secretAccessKey: "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",
  s3Url: "s3://senzmate-polar/product-types/flow-chart/",
};

export const submit = async (
  binFile,
  binFileName,
  flowChart,
  version,
  parameters,
  existingFlowChart,
  id,
  majorVersionUpgrade,
  addFlowChart,
  flowFileName,
) => {
 
  const dependencyMap = new Map();
  parameters.forEach(param => {
      const paramId = param.parameterId;
      const joinParameters = param.joinParameter;

      joinParameters.forEach(joinParamId => {
        if (!dependencyMap.has(joinParamId)) {
          dependencyMap.set(joinParamId, []);
        }
        if (!dependencyMap.get(joinParamId).includes(paramId)) {
          dependencyMap.get(joinParamId).push(paramId);
        }

        if (!dependencyMap.has(paramId)) {
          dependencyMap.set(paramId, []);
        }
        if (!dependencyMap.get(paramId).includes(joinParamId)) {
          dependencyMap.get(paramId).push(joinParamId);
        }
      });

      delete param.joinParameter;
    });


    const joinParametersObj = Array.from(dependencyMap).reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  
    let flowPath="";

  if (addFlowChart){
  if (!existingFlowChart) {

    flowPath = await uploadFlowChart(flowChart, flowChartConfig);
  }
  if (existingFlowChart&&existingFlowChart.URL !== "") {
    flowPath = existingFlowChart.URL;
    flowFileName=existingFlowChart.fileName;
  }}
  return updateProductWithFileLinks(
    id,
    version,
    parameters,
    binFile,
    binFileName,
    flowPath,
    dependencyMap,
    majorVersionUpgrade,
    addFlowChart,
    flowFileName
  );
};

export const uploadFlowChart = (flowFile, flowChartConfig) => {
  return uploadFile(flowFile, flowChartConfig)
    .then((data) => {
      return data.location;
    })
    .catch((err) => {
      return err;
    });
};

export const updateProductWithFileLinks = (
  id,
  version,
  remoteConfig,
  bin,
  binFileName,
  flow,
  joinParameters,
  majorVersionUpgrade,
  addFlowChart,
  flowFileName
) => {
  const versionObject = {
    versionNum: version,
    fileName: binFileName,
    remoteConfigurations: remoteConfig,
    joinParameters: joinParameters,
    majorVersionUpgrade: majorVersionUpgrade,
    ...(addFlowChart && { flowChartURL: flow,flowChartFileName:flowFileName }), // Conditionally include flowChartURL
  };
  const formData = new FormData();
  formData.append("binFile", bin);
  formData.append("version", JSON.stringify(versionObject));

  return axios.post(`${environment.host}/core/user/${localStorage.getItem("USER_ID")}/product/${id}`, formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err)
      return err;
    });
};

export const getDevicesWithProductType = async (
  productName
) => {
  try {
    const response = await axios.get(
      `${environment.host}/core/product/devices?productType=${productName}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      }
    );
    return { data: response.data.content, isError: false };
  } catch (error) {
    console.error("Error fetching devices:", error);
    return { isError: true, error: error };
  }
};