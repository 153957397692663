import {
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  Typography
} from '@material-ui/core';
import {Alert, Pagination} from '@material-ui/lab';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {SearchBar} from 'src/components/sub-components/SearchBar';
import {primaryBlue, secondaryTextColor, white} from 'src/components/colors';
import axios from 'axios';
import {useDispatch} from 'react-redux'
import {Arrow, DecoderIcon, DeleteIcon, EditIconLarge} from '../../../../components/Icons'
import {Link, useLocation, useParams} from 'react-router-dom'
import {environment} from 'src/environments/environment';
import {ConnectDevice} from '../ConnectDevice';
import {DeleteDecoder} from '../../delete-decoder/DeleteDecoder';
import { TestCodec } from '../../test-codec/TestCodec';
import {toast} from "react-toastify";
import useStyles from "src/app/maintenance/assets/styles";

export function ViewDecoder(props) {
  const classes = localStyles();
  const style =  useStyles();
  const id = useParams();
  const location = useLocation()
  const [devicesData, setDevicesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [type, setType] = useState('')
  const [editDevice, setEditDevice] = useState(false)
  const [testCodec, setTestCodec] = useState(false)
  const [deviceId, setDeviceId] = useState('')
  const [deleteDevice, setDeleteDevice] = useState(false)
  const [devicesArray, setDevicesArray] = useState([])
  const [multiDevices, setMultiDevices] = useState([])
  const [multiDevicesId, setMultiDevicesId] = useState([])
  const [toastOpen, setToastOpen] = useState(false)
  const [reqSuccess, setReqSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [existCodec, setExistCodec] = useState([])
  const [decoderName, setDecoderName] = useState('');
  const [decoderNames, setDecoderNames] = useState([]);
  const [decoders, setDecoders] = useState([])
  const [decoderId,setDecoderId] = useState('')
  var particularDecoder

  const { codecId } = useParams();

  useEffect(() => {
    try{
      getParticularDecoder()
      const path = window.location.pathname;
      setDecoderId(path.split('/')[1])
      if(location.state){
        setDecoderName(location.state.data.codecName)
        setDevicesArray(location.state.devices.filter((dev) => {
          return dev.magmaCodecId === null || !dev.hasOwnProperty('magmaCodecId')
        }))
      }else{
        getDeviceData()
      }
    }catch(err){

    }
  }, [])

  const getParticularDecoder = () => {
    setLoading(true)
    axios.get(
      environment.host + '/core/codec/' + id.codecId + '/connected-devices',
      {
        headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')}
      }
    ).then((res) => {
      setDevicesData(res.data.content)
    }).catch((err) => {
    })
  }

  useEffect(() => {
    setLoading(true)
    Promise.all([
      getAllCodec()
    ]).then(() => {
      setLoading(false); // Hide loading indicator when all API calls complete
    }).catch((error) => {
      console.error('Error fetching data:', error);
      setLoading(false); // Ensure loading indicator is hidden on error
    });
  }, [])

  const getAllCodec = () => {
    return new Promise<void>((resolve, reject) => {
      axios.get(
        environment.host + `/core/codec`,
        {
          headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')}
        })
        .then((response) => {
          setDecoders(response.data.content);
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    })
  }
  const getDeviceData = () => {
    setLoading(true)
    axios.get(
      environment.host + '/core/device',
      {
        headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')}
      }
    ).then((res) => {
      setDevicesArray(res.data.content.filter((dev) => {
        return dev.magmaCodecId === null || !dev.hasOwnProperty('magmaCodecId')
      }))
    }).catch(function (error) {
      if (error.response && error.response.status === 422) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went Wrong, Please try again later");
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const closePopup = () => {
    setEditDevice(false)
    setTestCodec(false)
    setDeleteDevice(false)
  }

  const selectMultiDevice = (option) => {
    if (multiDevicesId.includes(option.id)) {
      setMultiDevices(multiDevices.filter((device) => device != option))
      setMultiDevicesId(multiDevicesId.filter((id) => id != option.id))
    } else {
      setMultiDevices([...multiDevices, option])
      setMultiDevicesId([...multiDevicesId, option.id])
    }
  }

  const removeExistDecoder = () => {
    setExistCodec(decoderNames.filter((decoder) => decoder !== decoderName))
  }

  const multiSelectDevices = () => {
    if(multiDevicesId.length != 0){
      setMultiDevices([])
      setMultiDevicesId([])
    }else{
      setMultiDevices(devicesData)
      setMultiDevicesId(devicesData.map(device => device.id))
    }

  }

  const deleteSelectedDevices = () =>{
    console.log(multiDevicesId)
    if(multiDevicesId.length > 0){
      setType('multiple-delete')
      setDeleteDevice(true)
    }

  }

  return (
    <div>
      <div className={`testing`}>
      <div className="page-heading">
          Transcoding
        </div>
        {
          <Grid container className={style.container}>
            <Grid item xs={12} md={12} style={{
              display: "flex",
              height: "40px",
              marginLeft: "10px",
              marginRight: "70px",
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <Grid item md={12} xs={12} style={{display: "flex"}}>
                <DecoderIcon/>
                <Link to={'/codec'} style={{paddingLeft: '10px', textDecoration: 'none'}}>
                  <Typography style={{color: '#858DAA'}}>Codec</Typography>
                </Link>
                <Arrow/>
                <Typography>Connected Devices</Typography>
              </Grid>
              <Grid item md={12} xs={12}
                    style={{textAlign: "right", display: "flex", justifyContent: "flex-end", marginLeft: '30px'}}>
                <Button
                  variant='contained'
                  onClick={() => {
                    setType('device-connect'), setEditDevice(true)
                  }}
                  className={classes.connectDecoder}
                  style={{textTransform: "none"}}
                >Connect Devices</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} style={{display: "flex", height: "40px", margin: "25px 70px 25px 10px"}}>
              <Typography display="inline" style={{fontSize: "18px"}}>Devices Decoded With
              </Typography>
              <Typography style={{
                fontSize: '18px',
                paddingLeft: '5px',
                fontWeight: 'bold'
              }}>{decoderName}</Typography>
            </Grid>
            <Grid style={{
              textAlign: 'left',
              alignItems: 'center',
              display: 'flex',
              paddingLeft: '0px',
              justifyContent: 'center',
              margin: '0px 70px 10px 0px'
            }} item xs={12} md={12}>
              <Grid item xs={12} md={12}
                    style={{display: "flex", height: "40px", marginLeft: "10px", marginRight: "70px"}}>
                <Grid item style={{textAlign: "left", width: "275px"}}>
                  <SearchBar onChange={(e) => setSearch(e.target.value)} search="Search for device name or ID"/>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}
                    style={{textAlign: "right", display: "flex", justifyContent: "flex-end", marginLeft: '30px'}}>
                <Button
                  variant='contained'
                  onClick={() => {setTestCodec(true)}}
                  className={classes.connectDecoder}
                  style={{textTransform: "none"}}
                >Test Codec</Button>
              </Grid>
            </Grid>
            <Grid style={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              margin: '5px 70px 0 10px',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '16px'
            }}>
              <Checkbox className={style.radio}
                 checked={multiDevices.length != 0}
                onClick={() => multiSelectDevices()}
                />
              <label className={`${classes.deleteButton} ${multiDevicesId.length > 0 ? classes.deleteButtonEnable : ''}`} onClick={()=>deleteSelectedDevices()}>Delete Devices</label>

            </Grid>
            <Grid item xs={12} md={12} style={{marginTop: "20px", marginLeft: "10px", marginRight: '70px'}}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{
                        textAlign: "left", borderBottomColor: white,
                        borderTopColor: white,
                        backgroundColor: white,
                        width: '20px'
                      }}>
                        <Typography display="inline" className={style.tablehead}></Typography>
                      </TableCell>
                      <TableCell style={{
                        textAlign: "left", borderBottomColor: white,
                        borderTopColor: white,
                        backgroundColor: white
                      }}>
                        <Typography display="inline" className={style.tablehead}>ID</Typography>
                      </TableCell>
                      <TableCell style={{
                        textAlign: "left", borderBottomColor: white,
                        borderTopColor: white,
                        backgroundColor: white
                      }}>
                        <Typography display="inline" className={style.tablehead}>Device Name</Typography>
                      </TableCell>

                      <TableCell style={{
                        textAlign: "left", borderBottomColor: white,
                        borderTopColor: white,
                        backgroundColor: white
                      }}>
                        <Typography className={style.tablehead}>Action</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {devicesData !== undefined && devicesData.filter((obj) => {
                      if (search === "") {
                        return obj
                      } else if (obj.name.toLowerCase().includes(search.trim().toLowerCase()) || obj.id.includes(search.trim())) {
                        return obj
                      }
                    }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((device, i) => (
                      <TableRow key={i} style={{height: '48px'}}>
                        <TableCell style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white",
                          width: '20px',
                          paddingRight: '0px'
                        }}>
                          <Checkbox className={style.radio}
                                    id={device.id}
                                    value={device.name}
                                    checked={multiDevices.includes(device)}
                                    onClick={() => selectMultiDevice(device)}/>
                        </TableCell>
                        <TableCell style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white",
                          paddingLeft: '0px'
                        }}>
                          <Typography display='inline' className={classes.tableText}>{device.id}</Typography>
                        </TableCell>
                        <TableCell style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                        }}>
                          <Typography display='inline' className={classes.tableText}>
                            {device.name}
                          </Typography>
                        </TableCell>
                        <TableCell style={{
                          textAlign: "left",
                          borderBottomColor: "white",
                          borderTopColor: "white",
                          backgroundColor: ((i % 2) === 0 || (i / 2) === 0) ? "#F9F9F9" : "white"
                        }}>
                          <IconButton className={style.iconStyle} onClick={() => {
                            setType('edit-device'), setEditDevice(true), setDeviceId(device.id), removeExistDecoder()
                          }}title="Edit">
                            <EditIconLarge/>
                          </IconButton>
                          <IconButton className={style.iconStyle} onClick={() => {
                            setType('device-delete'), setDeleteDevice(true), setDeviceId(device.id)
                          }}title="Delete">
                            <DeleteIcon/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                    }
                  </TableBody>
                </Table>
              </TableContainer>
               <Grid container justifyContent="center">
                  <Pagination className={style.pagination}
                               count={
                                devicesData !== undefined
                                  ? Math.ceil(
                                      devicesData.filter(
                                        (dev) =>
                                          dev.name.toLowerCase().includes(search.trim().toLowerCase()) ||
                                          dev.id.includes(search.trim())
                                      ).length / rowsPerPage
                                    )
                                  : 0
                              }
                                page={page + 1}
                                onChange={(event, value) => setPage(value - 1)}
                              />
              </Grid>
            </Grid>
          </Grid>
        }
      </div>
      {loading &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80}/>
        </div>
      }
      <Snackbar
        open={toastOpen}
        autoHideDuration={3000}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert
          severity={reqSuccess === true ? "success" : "warning"}
        >
          {
            errorMessage.length > 0
              ? errorMessage
              : "Something went wrong"
          }
        </Alert>
      </Snackbar>

      <ConnectDevice
        type={type}
        decodersName={existCodec}
        open={editDevice}
        onclose={() => closePopup()}
        decoders={decoders}
        decoderIdView={id.codecId}
        deviceId={deviceId}
        codecName={decoderName}
        setDeviceData={setDevicesData}
        deviceData={devicesData}
        getParticularDecoder={() => getParticularDecoder()}
        getDeviceData={() => getDeviceData()}
        setDevicesArray={setDevicesArray}
        devicesArray={devicesArray}
        setToast={setToastOpen}
        setReqSuccess={setReqSuccess}
        setErrorMessage={setErrorMessage}
      />
      <DeleteDecoder
        type={type}
        open={deleteDevice}
        onClose={() => closePopup()}
        decoderId={id.codecId}
        deviceId={deviceId}
        setDeviceData={setDevicesData}
        deviceData={devicesData}
        getParticularDecoder={() => getParticularDecoder()}
        getDeviceData={() => getDeviceData()}
        setDevicesArray={setDevicesArray}
        devicesArray={devicesArray}
        setToast={setToastOpen}
        setReqSuccess={setReqSuccess}
        setErrorMessage={setErrorMessage}
        setLoading={setLoading}
        multiDevicesId = {multiDevicesId}
        setMultiDevicesId = {setMultiDevicesId}
        setMultiDevices = {setMultiDevices}
      />
      <TestCodec 
        open={testCodec}
        onclose={() => closePopup()}
        codecId={codecId}
      />
    </div>
  )
}


const localStyles = makeStyles((theme: Theme) => ({
  filter: {
    backgroundColor: white,
    color: secondaryTextColor,
    boxShadow: "none",
  },
  addDecoder: {
    backgroundColor: primaryBlue,
    color: white,
    boxShadow: "none",
    height: "40px",
    width: "200px",
    '&:hover': {
      backgroundColor: primaryBlue,
      color: white,
      boxShadow: "none"
    },
  },
  tableText: {
    fontSize: "14px",
    color: "#3C3C3C",
  },
  greenChip: {
    backgroundColor: "#03C69F1F",
    color: "#03C69F",
    height: "20px",
    marginRight: "5px",
    margin: "5px"
  },
  redChip: {
    backgroundColor: "#FC41411F",
    color: "#FC4141",
    height: "20px",
    marginRight: "5px",
    margin: "5px"
  },
  greenIndicator: {
    backgroundColor: "#03C69F1F",
    height: "20px",
    width: "30px"
  },
  redIndicator: {
    backgroundColor: "#FC41411F",
    height: "20px",
    width: "30px"
  },
  connectDecoder: {
    backgroundColor: white,
    color: primaryBlue,
    boxShadow: "none",
    height: "40px",
    width: "200px",
    borderColor: primaryBlue,
    border: "2px solid",
    '&:hover': {
      backgroundColor: white,
      color: primaryBlue,
      boxShadow: "none"
    },
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Poppins'
  },
  statusText: {
    width: "62px",
    borderRadius: "10px",
    fontSize: "14px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  status: {
    display: "grid",
    justifyContent: "center",
    alignContent: "center",
    height: "45px"
  },
  deleteButton: {
    width: "94px",
    height: "24px",
    background: "#F2F2F2 0% 0% no-repeat padding-box",
    borderRadius: "19px",
    opacity: 1,
    color: '#3C3C3C',
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor:'not-allowed'
  },
  deleteButtonEnable: {
    color:"red",
    background: "white 0% 0% no-repeat padding-box",
    border: '1px solid red',
    cursor:'pointer'
  }
}));
