const checkOTAAckTopicValidity = (text, deviceId, deviceName) => {
    const segments = text.split("/");
    const lastSegment = segments[segments.length - 1];
    if (lastSegment === deviceId) {
        return { valid: true, IMEIId: true, name: false };
    } else if (lastSegment === deviceName) {
        return { valid: true, IMEIId: false, name: true };
    }
    return { valid: false ,IMEIId: false, name: false };
};

const checkTopicValidity = (text, position, deviceId, deviceName) => {
    const segments = text.split("/");
    if (position !== null && segments.length >= position) {
        const identifier = segments[segments.length - position];
        if (identifier === deviceId || identifier === deviceName) {
            return {
                valid: segments[segments.length - 1] === "#",
                IMEIId: identifier === deviceId,
                name: identifier === deviceName
            };
        }
    } else {
        for (const segment of segments) {
            if (segment === deviceId) {
                return { valid: segments[segments.length - 1] === "#", IMEIId: true, name: false };
            } else if (segment === deviceName) {
                return { valid: segments[segments.length - 1] === "#", IMEIId: false, name: true };
            }
        }
    }
    return { valid: false,IMEIId: false, name: false  };
};

// Main validation function
const validateTopics = (remoteConfigStatus, otaUpgradableStatus, topics, deviceId, deviceName) => {
    const errors = {
        topicError: false,
        OTAAckTopicError: false,
        OTAUpgradableTopicError: false,
        OTARequestFormatError: false,
        OTAAckFormatError: false,
        RemoteConfigTopicError: false,
        RemoteConfigFormatError: false,
        RemoteConfigAckTopicError: false,
        RemoteConfigAckFormatError: false,
        mismatcheUniqueIdError: false
    };
    const IdInTopics = {
        remote: { IMEIId: false, name: false },
        OTA: { IMEIId: false, name: false },
        notSame: false
    };

    // OTA Validation
    if (otaUpgradableStatus) {
        const { otaRequestTopic, otaAckTopic } = topics;
        errors.OTAUpgradableTopicError = otaRequestTopic.trim() === "";
        errors.OTAAckTopicError = otaAckTopic.trim() === "";
        let otaRequestFormatValidity= { valid: false, IMEIId: false, name: false };
        let otaAckTopicValidity= { valid: false, IMEIId: false, name: false };

        if (!errors.OTAUpgradableTopicError) {
            otaRequestFormatValidity = checkTopicValidity(otaRequestTopic, 3, deviceId, deviceName);

            errors.OTARequestFormatError = !otaRequestFormatValidity.valid;
        }
        if (!errors.OTAAckTopicError) {
            otaAckTopicValidity = checkOTAAckTopicValidity(otaAckTopic, deviceId, deviceName);
            errors.OTAAckFormatError = !otaAckTopicValidity.valid;
        }
        if (!errors.OTAAckFormatError && !errors.OTARequestFormatError ){
            if( otaAckTopicValidity.name && otaRequestFormatValidity.name) {
            IdInTopics.OTA.name = true;
        } else if (otaAckTopicValidity.IMEIId && otaRequestFormatValidity.IMEIId) {
            IdInTopics.OTA.IMEIId = true;
        } else {
            IdInTopics.notSame = true;
        } 
    }
        errors.topicError = errors.OTAAckTopicError  || errors.OTAUpgradableTopicError ||
                         errors.OTAAckFormatError || errors.OTARequestFormatError 

    }


    // Remote Config Validation
    if (remoteConfigStatus) {
        const { remoteConfigTopic, remoteConfigAckTopic } = topics;
        errors.RemoteConfigTopicError = remoteConfigTopic.trim() === "";
        errors.RemoteConfigAckTopicError = remoteConfigAckTopic.trim() === "";
        let remoteConfigTopicValidity= { valid: false, IMEIId: false, name: false };
        let remoteConfigAckTopicValidity={ valid: false, IMEIId: false, name: false };

        if (!errors.RemoteConfigTopicError) {
            remoteConfigTopicValidity = checkTopicValidity(remoteConfigTopic, 3, deviceId, deviceName);
            errors.RemoteConfigFormatError = !remoteConfigTopicValidity.valid;
        }
        if (!errors.RemoteConfigAckTopicError) {
            remoteConfigAckTopicValidity = checkTopicValidity(remoteConfigAckTopic, null, deviceId, deviceName);
            errors.RemoteConfigAckFormatError = !remoteConfigAckTopicValidity.valid;
        }
        errors.topicError = errors.topicError || errors.RemoteConfigTopicError  || errors.RemoteConfigAckTopicError ||
                         errors.RemoteConfigFormatError || errors.RemoteConfigAckFormatError
        if (!IdInTopics.notSame && !errors.topicError) {
            if (
                remoteConfigTopicValidity.IMEIId &&
                remoteConfigAckTopicValidity.IMEIId &&
                (!otaUpgradableStatus || IdInTopics.OTA.IMEIId)
            ) {
                IdInTopics.notSame = false;
            } else if (
                remoteConfigTopicValidity.name &&
                remoteConfigAckTopicValidity.name &&
                (!otaUpgradableStatus || IdInTopics.OTA.name)
            ) {
                IdInTopics.notSame = false;
            } else {
                IdInTopics.notSame = true;
            }
        }
    }
    if(!errors.topicError){
    errors.mismatcheUniqueIdError = IdInTopics.notSame;
    }
    return errors;
};
export default validateTopics;