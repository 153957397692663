import * as React from 'react';
import {useEffect, useState} from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {environment} from 'src/environments/environment';
import {white} from 'src/components/colors';
import {CloseIconColor, CloseIconLarge} from 'src/components/Icons';
import axios from 'axios';
import useStyles from "src/app/maintenance/assets/styles";


const localStyles = makeStyles((themes: Theme) =>
  createStyles({
    inputGridsLeft: {
      margin: '5px',
    },
    inputGridsRight: {
      marginLeft: '50px',
      margin: '5px',
      [themes.breakpoints.down('md')]: {
        marginLeft: "5px"
      },
    },
    iconButton: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "white"
      }
    },
    action: {
      marginTop: "10px",
      fontFamily:"poppins",
      width: 'auto',
      marginLeft: '5px',
      height: '40px',
      borderRadius: '5px',
      display: 'flex',
      paddingLeft: '10px',
      alignItems: 'center',
      color: 'white',
      backgroundColor: '#2A7BEC'
    },
  }),
);

export function PopUp(props) {
  const classes = localStyles()
  const style = useStyles();
  const [newActionText, setNewActionText] = useState('')

  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [actions, setActions] = useState([])
  const [nameError, setNameError] = useState({error: false, errorMsg: ""})
  const [actionTextError, setActionTextError] = useState({error: false, errorMsg: ""})

  //loading state
  const [loading, setLoading] = useState(false)

  //Function for read new action text
  const saveNewActionText = () => {
    let oldActions
    if (newActionText === undefined || newActionText === "") {
      setActionTextError({error: true, errorMsg: "Action name cannot be empty"})
      return
    } else {
      setActionTextError({error: false, errorMsg: ""})
    }

    if (actions) {
      oldActions = [...actions]
    } else {
      oldActions = []
    }

    const currentIndex = oldActions.length;
    let notIncludeActions = oldActions.some(action => action.action_name.toLowerCase() == newActionText.toLowerCase())
    if (!notIncludeActions || currentIndex == 0) {
      oldActions[currentIndex] = {action_name: newActionText, selected: null}
      setNewActionText("")
    } else {
      setActionTextError({error: true, errorMsg: "Action already included"})
    }

    setActions(oldActions)
  }
  //Function to read privilege name
  const readName = (e) => {
    const enteredValue = e.target.value;
    setName(enteredValue.trim());

    if (enteredValue.trim() === "") {
      setNameError({error: true, errorMsg: "Name is required"});
    } else {
      setNameError({error: false, errorMsg: ""});
    }
  };

  const removeAction = (action) => {
    const newActions = actions.filter(a => !(a.action_name === action.action_name))
    setActions(newActions)

  }

  //Function to read description
  const readDescription = (e) => {
    const enteredValue = e.target.value;
    setDescription(enteredValue.trim());
  }

  //Save or update privilege
  const sendData = () => {
    const errors = [];
    const addError = (field, message) => {
      errors.push({field, message});
    };
    const data = {
      name: name,
      description: description,
      actions: actions,
      parentId: props.parentId,
      child: [],
      childIds: []
    }


    let filterPrivilege = [];
    if (props.allPrivileges && Array.isArray(props.allPrivileges) && name) {
      filterPrivilege = props.allPrivileges.filter(p => p.name && p.name.toLowerCase() === name.toLowerCase());
    }
    if (filterPrivilege.length != 0) {
      addError("name", "Privilege Name Already Exists")
      props.setAlertOpen(true)
      props.setSeverity("error")
      props.setTopic("Error")
      props.setMessage("Privilege Name Already Exists")
      setTimeout(() => {
        setLoading(false)
        props.setAlertOpen(false)
      }, 1000)
    }
    if (props.type === "add-new") {
      if (data.name === undefined || data.name === "") {
        addError("name", "Name is required");
      }
    }
    if (props.type === "add-high") {
      data.parentId = "high-privilege"
      if (data.name === undefined || data.name === "") {
        addError("name", "Name is required");
      }
    }
    if (props.type === "edits") {
      if (data.name === undefined || data.name === "") {
        data.name = props.defaultValues.name
      }

      if (data.description === undefined || data.description === "") {
        data.description = props.defaultValues.description
      }
    }

    if (errors.length > 0 || nameError.error == true || actionTextError.error == true) {

      errors.forEach((error) => {
        const setError = setNameError 
        setError({error: true, errorMsg: error.message});
      });
      return;
    }
    console.log(data)
    // If no errors, proceed with API request
    if (props.type === "add-new") {
      apiRequest(data, "POST");
    } else if (props.type === "add-high") {
      apiRequest(data, "POST");
    } else if (props.type === "edits") {
      apiRequest(data, "PUT");
    }

  }

  const apiRequest = (data, method) => {
    const errors = [];
    setLoading(true)
    let url = `${environment.host}/ums/super/user-privilege`
    if (method === "PUT") {
      url = url + "/" + props.defaultValues.id  //When changing the id request parameter id should be the old id
    }
    axios({
        url,
        method: method,
        headers: {'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('ACCESS_TOKEN')},
        data: data
      })
      .then((response) => {
          props.setAlertOpen(true)
          props.setSeverity("success")
          props.setTopic("Success")
          if (props.type == 'edits') {
            props.setMessage("Privilege Updated Successfully")
          } else if (props.type == 'add-high' || props.type == 'add-new') {
            props.setMessage("Privilege Added Successfully")
          }

          props.onClose()
          setLoading(false)
          setActions([])
          setDescription(undefined)
          setName(undefined)
          setNewActionText("")
          setTimeout(() => {

            props.setAlertOpen(false)

          }, 2000)
      })
      .catch((error) => {
        if (error.response.data.message == 'Data already exists') {
          setLoading(false)
        } else {
          props.setAlertOpen(true)
          props.setSeverity("error")
          props.setTopic("Error")
          props.setMessage(error.response.data.message)
        }

        setTimeout(() => {
          setLoading(false)
          props.setAlertOpen(false)
        }, 1000)
      })
  }

  const closePopUpWindow = () => {
    if (props.type == "add-high" || props.type === "add-new") {
      setActions([])
      setDescription("")
      setName("")
    }
    props.onCancel();
    setNameError({error: false, errorMsg: ""});
    setActionTextError({error: false, errorMsg: ""})
    setNewActionText("")
  }

  const readActionName = event => {
    setNewActionText(event.target.value)
    if (event.target.value == "") {
      setActionTextError({error: false, errorMsg: ""})
    }
  }


  useEffect(() => {
    if (props.type === "edits") {
      setActions(props.defaultValues.actions)
    } else (
      setActions([])
    )
  }, [props.open])

  return (
    <Dialog open={props.open}>
      <Grid container>
        <Grid item xs={10} md={10}>
          <DialogTitle>{props.type === "edits" ? "Update" : props.type === "add-high" ? "Add High" : "Add Child"} Privilege</DialogTitle>
        </Grid>
        <Grid item xs={2} md={2} style={{textAlign: "right"}}>
          <IconButton onClick={() => {
            closePopUpWindow()
          }}>
            <CloseIconColor/>
          </IconButton>
        </Grid>
      </Grid>

      <Divider/>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={12} className={classes.inputGridsLeft}>
            <Grid container>
              <Grid item>
                <Typography className={style.typo}>Enter Privilege Name</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <input defaultValue={props.type === "edits" ? props.defaultValues.name : ""} type="text"
                     onChange={(e) => readName(e)} id="nameBar" className={style.formInput}/>
              {nameError.error ?
                <Grid className={style.errorText}>
                  {nameError.errorMsg}
                </Grid>
                : <></>
              }
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} className={classes.inputGridsLeft}>
            <Grid container>
              <Grid item>
                <Typography className={style.typo}>Enter Description</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <input defaultValue={props.type === "edits" ? props.defaultValues.description : ""} type="text"
                     onChange={(e) => readDescription(e)} id="nameBar"  className={style.formInput}/>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} className={classes.inputGridsLeft}>
            <Typography className={style.typo}>Select Actions</Typography>
            <Grid container>
              <Grid container alignItems="center" spacing={1}>
                <Grid item xs={8} sm={9}>
                  <input onChange={readActionName} value={newActionText} className={style.formInput} type="text"/>
                  {
                    actionTextError.error ?
                      <Grid
                       className={style.errorText}>
                        {actionTextError.errorMsg}
                      </Grid>
                      : <></>
                  }
                </Grid>
                <Grid item xs={3} md={3} style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Button onClick={saveNewActionText} variant='contained' className={style.saveButton}>
                    ADD
                  </Button>
                </Grid>
              </Grid>
              <Grid container className={style.formInput} style={{marginTop:'5px', height: '100px', overflowY: 'auto'}}>
                {
                  actions && actions.map((action, index) => (
                    <Grid item key={index} className={classes.action}>

                      <Grid item>{action.action_name}</Grid>
                      <IconButton onClick={() => removeAction(action)}>
                      <CloseIconLarge color={white} />
                      </IconButton>
                    </Grid>
                  ))
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent='flex-end' style={{marginTop: '15px'}}>
          <Grid item>
            <Button onClick={() => closePopUpWindow()} variant='contained' className={style.backButton} style={{marginRight:'10px'}}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={sendData} variant='contained' className={style.saveButton}>
              {props.type === "edits" ? "Update" : "Add"}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
      {loading &&
        <div style={{
          position: 'fixed',
          left: 0,
          top: 0,
          backgroundColor: '#000000',
          opacity: 0.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9999,
          width: '100vw',
          height: '100vh'
        }}>
          <CircularProgress size={80}/>
        </div>
      }
    </Dialog>

  )
}



