import * as React from 'react';
import {useEffect, useState} from 'react';
import {createTheme, Grid, InputBase, ThemeProvider, Typography, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, TablePagination, Button } from "@material-ui/core";
import useStyles from "../../../assets/styles";
import { RefreshApiIcon } from 'src/components/Icons';
import { Loader } from 'src/app/maintenance/test-automation/components/Loader';

const theme = createTheme({
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export default function Step4DataPreview({devices,
                                           fetchDevices,
                                           loading,
                                           selectedDevice,
                                           reloadData
                                         }) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedDevice) {
        await fetchDevices(selectedDevice);
      }
    };
    fetchData();
  }, [selectedDevice]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems='center' justifyContent='center'>
        <Grid container className={classes.root}>
          <Grid container className={classes.formContent}>
            <Grid item xs={12} md={12}>
            <Grid container justifyContent='flex-end'>
              <Grid item className={classes.buttonContainer} style={{marginBottom:"10px"}}>
                <Button onClick={reloadData} className={classes.refreshButton}>
                    <RefreshApiIcon />
                    <Typography style={{marginLeft:"10px"}}>Refresh</Typography> 
                </Button>
              </Grid>
              </Grid>
              <Grid container alignItems="center" justifyContent="flex-end" style={{marginTop:"30px", marginBottom:"15px"}}>
                <Grid item xs={9} md={12}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Time</TableCell>
                          <TableCell>Entity ID</TableCell>
                          <TableCell>Data Preview</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell align="center" colSpan={4}>
                              <Typography>Loading devices...</Typography>
                            </TableCell>
                          </TableRow>
                        ) : devices && devices.length > 0 ? (
                          devices.map((device, index) => (
                            <TableRow key={index}>
                              <TableCell>{device.lastSeen}</TableCell>
                              <TableCell>{device.id}</TableCell>
                              <TableCell>{device.lastRawData}</TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={4}>No data available</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Grid container justifyContent='center' style={{marginRight:"20px"}}>
                    <Grid item xs={6} md={6} style={{marginTop:"10px"}}>
                      <TablePagination
                        rowsPerPageOptions={[]}
                        component="div"
                        count={devices !== undefined && devices.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Loader loading={loading}/>
    </ThemeProvider>
  );
}
